.btn {
  @include fz-14;
  font-weight: 600;
  line-height: 2rem;
  color: $color-text;
  height: 4.4rem;
  padding: 1.2rem 3.8rem;
  font-family: var(--font-pri);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.8rem;
  position: relative;
  overflow: hidden;
  border: 0.1rem solid $color-pri;
  &::before {
    @include transition;
    position: absolute;
    content: "";
    left: -100%;
    width: 100%;
    top: 0;
    height: 100%;
    z-index: 1;
    background-color: $color-pri;
  }
  & > * {
    position: relative;
    z-index: 2;
  }
  &:hover {
    &::before {
      left: 0;
    }
  }
  @include max(575.98px) {
    padding: 1.2rem 2rem;
  }
}
.btn-sec {
  background-color: $color-pri;
  border: 0;
  &::before {
    background-color: #37003c;
  }
  &:hover {
    color: #fff;
  }
}
