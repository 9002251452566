.woo-variation-swatches
  .wvs-style-squared.variable-items-wrapper
  .variable-item:not(.radio-variable-item) {
  border-radius: 0.8rem !important;
  height: 100%;
  margin: 0 !important;
  padding: 0 !important;
  width: fit-content;
}
.woo-variation-swatches
  .variable-items-wrapper
  .variable-item:not(.radio-variable-item) {
  box-shadow: none !important;
  border: 0.1rem solid rgba(191, 191, 191, 0.5);
}
.woo-variation-swatches
  .wvs-style-squared.variable-items-wrapper
  .variable-item:not(.radio-variable-item).button-variable-item
  .variable-item-span {
  @include fz-14;
  font-weight: 600;
  line-height: 150%;
  color: #393d48;
  padding: 1.2rem;
}
.woo-variation-swatches.wvs-show-label .variations td,
.woo-variation-swatches.wvs-show-label .variations th {
  font-weight: 500;
  @include fz-14;
  color: #393d48;
  line-height: 150%;
  margin-bottom: 1.2rem;
}
.reset_variations {
  background-color: #fbbf24;
  padding: 1rem 1.2rem;
  border-radius: 1rem;
  display: inline-block;
  margin-top: 1.2rem;
}
.wvg-gallery-thumbnail-image {
  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
    padding: 1.5rem 1rem;
    border: 0.1rem solid #94a3b8;
    border-radius: 6px;
    img {
      border-radius: 0.8rem;
    }
  }
}
.prod-sale-content {
  .price {
    color: $color-text;
    del {
      font-size: 2rem;
      text-decoration: none;
      bdi {
        text-decoration: line-through;
      }
    }
    bdi {
      color: #ef4444;
    }
    ins {
      text-decoration: none;
      color: #ef4444;
    }
  }
  .variations_form {
    .check-list {
      margin-top: 2.4rem;
      margin-bottom: 2.4rem;
    }
  }
}
.quantity.counter-box {
  display: inline-flex;
  overflow: hidden;
}
.woocommerce:where(body:not(.woocommerce-block-theme-has-button-styles))
  button.button.alt {
  padding: 1.6rem 2rem;
  @include fz-14;
  font-weight: 600;
  font-family: var(--font-pri);
  border-radius: 0.8rem;
  border: 0.1rem solid #fbbf24;
  background: transparent !important;
  color: #1c212e !important;
  min-width: 21rem;
  text-align: center;
  @include transition;
  &:hover {
    background: #fbbf24 !important;
  }
}
.wc-buy-now-btn.wc-buy-now-btn-single.single_add_to_cart_button.button.alt {
  background: #fbbf24 !important;
  padding: 1.6rem 2rem;
  @include fz-14;
  font-weight: 600;
  font-family: var(--font-pri);
  border-radius: 0.8rem;
  border: 0.1rem solid #fbbf24;
  background: #fbbf24 !important;
  @include transition;
  min-width: 21rem;
  text-align: center;
  &:hover {
    background: transparent !important;
  }
}
.woocommerce:where(body:not(.woocommerce-block-theme-has-button-styles))
  a.button.disabled,
.woocommerce:where(body:not(.woocommerce-block-theme-has-button-styles))
  button.button.disabled {
  opacity: 0.5;
  cursor: not-allowed;
  padding: 1.6rem 2rem !important;
}
.prod-require {
  margin-top: 0.4rem;
}
.stock.in-stock {
  color: #ef4444;
  font-weight: 600;
  margin-bottom: 1.6rem;
}
.woo-variation-swatches
  .variable-items-wrapper
  .variable-item:not(.radio-variable-item).selected,
.woo-variation-swatches
  .variable-items-wrapper
  .variable-item:not(.radio-variable-item).selected:hover {
  border: 0.1rem solid #fbbf24;
}
.wc-block-components-notice-banner
  > .wc-block-components-notice-banner__content
  .wc-forward {
  &:hover {
    color: #fbbf24 !important;
    background: transparent !important;
  }
}
.woo-variation-gallery-thumbnail-wrapper {
  margin-top: 2rem;
}
.cart-table .table tbody tr td {
  text-align: left;
  padding: 1.2rem 0.5rem;
}
.woocommerce table.shop_table {
  border-radius: 1.2rem !important;
  margin-bottom: 0;
  border: 0;
}
.woocommerce table.shop_table th {
  text-align: center;
  min-width: 10rem;
}
.woocommerce table.shop_table td {
  line-height: 150%;
  font-family: var(--font-pri);
  font-weight: 500;
  &.title {
    @include fz-14;
    color: #000;
    min-width: 20rem;
  }
  &.product-price,
  &.product-subtotal {
    @include fz-15;
    color: #475569;
  }
  &.product-quantity {
    .counter-box {
      height: 2.4rem;
      border-radius: 0.4rem;
      button {
        width: 2rem;
        img {
          max-width: 0.8rem;
        }
      }
    }

    .counter-text.qty {
      @include fz-12;
      width: 4rem;
    }
  }
  &.product-remove {
    padding-left: 3rem;
    padding-right: 0.5rem;
  }
}
.variation {
  display: flex;
  flex-wrap: wrap;
  row-gap: 0.5rem;
  margin-top: 0.5rem;
  color: #475569;
  line-height: 150%;
  @include fz-14;
  & > * {
    width: 50%;
  }
  dt {
    font-weight: 600;
  }
  dd {
    font-weight: 400;
  }
}
.woocommerce a.remove {
  color: #131825 !important;
  &:hover {
    background: #131825 !important;
  }
}
.payment-item {
  .woocommerce-Price-amount.amount {
    display: flex;
    bdi {
      display: flex;
    }
  }
}
.woo-variation-gallery-slider-wrapper .slick-initialized .slick-slide {
  @include flex-center;
}
.woocommerce {
  .select2 {
    max-width: unset;
  }
  .select2-container .select2-selection--single {
    height: 4.8rem;
    border: 0.1rem solid #d9d9d9;
    border-radius: 0.8rem;
  }
  .sort-sale {
    .select2-container .select2-selection--single {
      height: 4.8rem;
      border: 0;
      border-radius: 0;
    }
  }
  .select2-container--default
    .select2-selection--single
    .select2-selection__arrow {
    height: 4.8rem;
    right: 0.5rem;
  }
  .select2-container--default
    .select2-selection--single
    .select2-selection__rendered {
    line-height: 4.8rem;
    color: #393d48;
  }
}
.wc_payment_method {
  .checkmark {
    align-items: center;
  }
}
.woocommerce-MyAccount-navigation {
  ul {
    li {
      background: transparent;
      @include transition;
      border-radius: 0.8rem;
      &:not(:first-child) {
        margin-top: 1rem;
      }
      a {
        color: #0f172a;
        @include fz-16;
        font-weight: 500;
        font-family: var(--font-pri);
        display: block;
        width: 100%;
        padding: 1.5rem;
      }
      &.is-active {
        background: $color-pri;
        a {
          font-weight: 600;
        }
      }
      &:hover {
        background: $color-pri;
      }
    }
  }
}
.woocommerce-MyAccount-content {
  @include fz-16;
  color: $color-text;
  font-family: var(--font-pri);
  .woocommerce-Button {
    padding: 1rem;
    background: $color-pri !important;
    color: #0f172a !important;
  }
}
.woocommerce-notices-wrapper {
  @include fz-14;
  font-family: var(--font-pri);
  color: #ef4444;
}
.woocommerce table.my_account_orders td,
.woocommerce table.my_account_orders th {
  text-align: center;
}
.woocommerce table.my_account_orders .button {
  height: fit-content;
}
.woocommerce-Address {
  width: 100%;
  address {
    @include fz-16;
    line-height: 150%;
    color: $color-text;
  }
}
.woocommerce-Address-title {
  margin: 2rem 0;
  h3 {
    @include fz-24;
    font-weight: 700;
    color: #393d48;
    line-height: 150%;
  }
  a {
    @include fz-16;
    background: #fbbf24;
    padding: 1rem;
    display: inline-block;
    line-height: 150%;
    color: $color-text;
    border-radius: 0.4rem;
  }
}
.sort-price,
.sort-sale {
  position: relative;
  .select2-dropdown {
    top: 4rem;
  }
}
.para-price {
  display: flex;
  flex-direction: column-reverse;
}
.price.old {
  color: #ef4444;
  text-decoration: line-through;
  font-size: 1.6rem;
}

// =========== show hide content
.show-hide-content {
  height: 80rem;
  overflow: hidden;
  @include transition;
  position: relative;

  &.is-active {
    overflow: unset;
    height: unset;
    @include transition;
    .ico-show-hide {
      position: relative;
      img {
        @include transition;
        transform: rotate(-180deg);
      }
    }
  }
}
.ico-show-hide {
  position: absolute;
  height: 14rem;
  width: 100%;
  left: 0;
  right: 0;
  z-index: 1;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 2rem;
  cursor: pointer;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff00', endColorstr='#ffffff',GradientType=0 );
  background: -moz-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  @include fz-16;
  font-weight: 600;
  color: $color-pri;
  column-gap: 0.4rem;
  img {
    @include transition;
  }
  span {
    @include transition;
  }
}
.prod-heading {
  display: flex;
  gap: 0.8rem;
}
.prod-new {
  color: #fff;
  font-family: var(--font-pri);
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 140%;
  padding: 0.4rem 0.8rem;
  display: block;
  border-radius: 0.4rem;
  background: #ef4444;
  margin-top: 1rem;
  height: fit-content;
  animation: zoom-in-zoom-out 1s linear infinite alternate;
}
@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(0.8, 0.8);
  }
  50% {
    transform: scale(0.9, 0.9);
  }
  100% {
    transform: scale(1, 1);
  }
}
.info-cate {
  display: flex;
  align-items: center;
  .product-cate {
    position: relative;
    @include fz-14;
    &:not(:first-child) {
      margin-left: 3.2rem;
      &::before {
        position: absolute;
        content: "";
        width: 0.1rem;
        height: 100%;
        background: #bfbfbf;
        top: 50%;
        left: -1.6rem;
        transform: translateY(-50%);
      }
    }
    a {
      @include transition;
      &:hover {
        color: $color-pri;
        text-decoration: underline;
      }
    }
    @include max(575.98px) {
      @include fz-12;
      &:not(:first-child) {
        margin-left: 1rem;
        &::before {
          left: -0.5rem;
        }
      }
    }
  }
}
.prod-require {
  border-radius: 0.8rem;
  overflow: hidden;
  border: 1px solid #e46e00;
  .title {
    background: #e46e00;
    padding: 1.6rem 3rem;
    color: #fff;
    text-align: center;
    @include fz-18;
    font-weight: 700;
    line-height: 140%;
  }
}
.prod-contact {
  margin-top: 0;
  padding: 0;
  &::before {
    content: unset;
  }
  @include max(575.98px) {
    row-gap: 0;
  }
}
.prod-item {
  padding: 2rem 1rem;
  & + & {
    border-left: 1px solid #ef4444;
    padding-left: 1rem;
    &::before {
      content: unset;
    }
    @include max(575.98px) {
      border-top: 1px solid #ef4444;
      border-left: none;
    }
  }
}
.prod-sale-content {
  .product-price {
    &.price {
      background: #f1f1f1;
      color: #ef4444;
      @include fz-32;
      padding: 0.4rem 1.6rem;
      font-weight: 700;
      line-height: 140%;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      del {
        margin-right: 1rem;
        color: #bfbfbf;
        @include fz-18;
        font-weight: 500;
        line-height: 140%;
        bdi {
          color: #bfbfbf;
          @include fz-18;
          font-weight: 500;
          line-height: 140%;
        }
      }
      .prod-new {
        width: fit-content;
        display: inline-block;
        padding: 0.4rem;
        margin-top: 0;
        animation: unset;
        margin-left: 1rem;
      }
      @include max(575.98px) {
        padding: 1rem 0.4rem;
        @include fz-20;
        .prod-new {
          @include fz-10;
        }
      }
    }
  }
}
.woocommerce-Price-currencySymbol {
  text-transform: lowercase;
}
.size-note {
  display: inline-block;
  margin-top: -0.4rem;
  margin-left: 0.4rem;
  img {
    @include box(1.2rem);
  }
}
.size-text {
  color: #393d48;
  @include fz-14;
  font-weight: 500;
  line-height: 150%;
}
.product-size {
  margin-top: 0.4rem;
  display: flex;
  column-gap: 6.4rem;
  row-gap: 1rem;
  .material-list {
    margin-top: 0;
  }
  @include max(767.98px) {
    column-gap: 3rem;
  }
  @include max(575.98px) {
    flex-direction: column;
  }
}
.product-material {
  margin-top: 0.4rem;
  display: flex;
  column-gap: 2.4rem;
  row-gap: 1rem;
  span {
    @include fz-14;
    height: fit-content;
  }
  .material-list {
    margin-top: 0;
    column-gap: 0.8rem;
    row-gap: 0.8rem;
    flex: 1;
  }
  .material-item {
    @include flex;
    gap: 1rem;
    column-gap: 0.5rem;
    padding: 1rem;
  }
  @include max(575.98px) {
    flex-direction: column;
    .material-list {
      flex-wrap: nowrap;
      overflow: auto hidden;
      &::-webkit-scrollbar {
        width: 0;
      }
    }
    .material-item {
      min-width: 18rem;
      flex-wrap: nowrap;
    }
  }
}
.material-item {
  height: unset;
  padding: 1.2rem 1.6rem;
  &.is-active {
    border-color: #393d48;
  }
}
.woo-variation-swatches
  .variable-items-wrapper
  .variable-item:not(.radio-variable-item).selected,
.woo-variation-swatches
  .variable-items-wrapper
  .variable-item:not(.radio-variable-item).selected:hover {
  border-color: #393d48;
}
.material-img {
  overflow: hidden;
  width: fit-content;
  height: fit-content;
  @include flex-center;
  img {
    @include box(3.6rem);
    border-radius: 0.4rem;
    object-fit: cover;
  }
}
.material-content {
  display: flex;
  flex-direction: column;
}
.material-name {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  color: #393d48;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 150%;
}
.material-price {
  color: rgba(239, 68, 68, 0.96);
  font-size: 1rem;
  font-weight: 500;
  line-height: 150%;
}
.monopoly {
  padding: 1.6rem;
  text-align: center;
  display: block;
  width: 100%;
  color: #fff;
  @include fz-16;
  font-weight: 600;
  line-height: 150%;
  border-radius: 0.8rem;
  background: #e46e00;
  @include max(575.98px) {
    font-size: 1.4rem;
  }
}
.ordernow-heading {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  row-gap: 3.2rem;
}
.ordernow-modal {
  max-width: 75.2rem;
  width: 100%;
  padding: 8rem;
  position: relative;
  border-radius: 2.4rem;
  overflow: hidden;
  a {
    &.close-modal {
      display: none;
    }
  }
  .close-box {
    position: absolute;
    top: 4rem;
    right: 4rem;
    img {
      @include box(1.6rem);
    }
  }
  @include max(1199.98px) {
    padding: 5rem;
  }
  @include max(991.98px) {
    padding: 5rem 3rem;
  }
  @include max(767.98px) {
    padding: 3rem 1.5rem;
  }
  @include max(575.98px) {
    padding: 2rem 1.5rem;
  }
}
.ordernow-heading {
  color: #393d48;
  font-size: 2rem;
  font-weight: 700;
  line-height: 140%;
  @include max(575.98px) {
    row-gap: 1rem;
  }
}
.ordernow-title {
  text-align: center;
}
.ordernow-name {
  color: #ef4444;
}
.ordernow-form {
  margin-top: 3.2rem;
  form {
    width: 100%;
  }
  .form-group {
    width: 50%;
    display: inline-flex;
    margin-bottom: 3.2rem;
    &.w-100 {
      width: 100%;
    }
    @include max(575.98px) {
      width: 100%;
      margin-bottom: 2rem;
    }
  }
  .form-box {
    display: flex;
    flex-wrap: wrap;
    row-gap: 1rem;
    margin: 0 -1.6rem;
    .form-group {
      padding: 0 1.6rem;
    }
    @include max(575.98px) {
      flex-direction: column;
      gap: 0;
    }
  }
  textarea.form-control {
    height: 13.9rem;
    @include max(575.98px) {
      height: 10rem;
    }
  }
  .btn {
    width: 100%;
    padding: 1.6rem;
  }
  @include max(575.98px) {
    margin-top: 2rem;
  }
}
.prod-thumb {
  @include max(991.98px) {
    display: flex;
    align-items: center;
    justify-content: center;
    .swiper-wrapper {
      width: fit-content;
    }
  }
  @include max(767.98px) {
    .thumb-item {
      @include box(5.4rem);
      padding: 0.5rem;
    }
  }
}
@include max(767.98px) {
  .prod-sale {
    row-gap: 2rem;
  }
}
.prod-fixed {
  display: none;
  @include max(575.98px) {
    display: flex;
    margin: 0 -1.5rem;
  }
  .prod-support {
    flex: 1;
    background: #fbbf24;
    padding: 1rem 1.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 0.2rem;
    @include max(389.98px) {
      padding: 1rem;
    }
  }
  .support-text {
    color: #fff;
    line-height: 150%;
    font-size: 1.2rem;
    font-weight: 600;
    display: flex;
    flex-direction: column;
  }
  .support-phone {
    font-size: 1.4rem;
  }
  .prod-text {
    width: 58%;
    background: #ef4444;
    padding: 1rem 3rem;
    color: #fff;
    text-align: center;
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 2rem;
    @include max(389.98px) {
      padding: 1rem 2rem;
    }
  }
}
.product-list {
  .product-price {
    @include fz-16;
  }
}
.woo-variation-swatches .variable-items-wrapper {
  margin-top: 0;
  column-gap: 0.8rem;
  row-gap: 0.8rem;
  flex: 1;

  @include max(575.98px) {
    column-gap: 0.4rem;
  }
}
.material-item.variable-item.image-variable-item {
  width: fit-content !important;
  height: unset !important;
}
.material-item.variable-item.image-variable-item .variable-item-contents {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  column-gap: 0.5rem;
  padding: 1rem;
  @include max(575.98px) {
    padding: 0.4rem;
  }
}
.custom-variation-swatches-image-attribute {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  column-gap: 0.5rem;
}
.woo-variation-swatches
  .variable-items-wrapper
  .variable-item:not(.radio-variable-item).color-variable-item.selected:not(
    .no-stock
  )
  .variable-item-contents:before,
.woo-variation-swatches
  .variable-items-wrapper
  .variable-item:not(.radio-variable-item).image-variable-item.selected:not(
    .no-stock
  )
  .variable-item-contents:before {
  background-size: 35% !important;
}
@include max(575.98px) {
  .material-item.variable-item.image-variable-item {
    min-width: 16rem;
  }
}
.check-list {
  margin-top: 2.4rem;
}
.woo-variation-gallery-thumbnail-slider {
  display: flex;
}
.wvg-gallery-thumbnail-image {
  width: 22%;
  cursor: pointer;
}
