// Core
@import "_core/reset";
@import "_core/mona";
@import "_core/variable";
@import "_core/font";
@import "_core/base";
@import "_core/flex";
// module
@import "_modules/mixin";
@import "_modules/title";
@import "_modules/breadcrumb";
@import "_modules/pagination";

// Component
@import "_components/header";
@import "_components/footer";

// Layout
@import "_layouts/btn";
@import "_layouts/form";
// Pages
@import "_pages/home";
@import "_pages/product";
@import "_pages/news";
@import "_pages/sign";
@import "_pages/policy";
@import "_pages/about";
@import "_pages/contact";
@import "_pages/cart";
@import "_pages/additional";
