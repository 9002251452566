:root {
  --font-pri: "Quicksand", sans-serif;
  --font-sec: "UTM Helve", sans-serif;
  --font-three: "Poppins", sans-serif;
  --font-four: "Manrope", sans-serif;
  --size-hd: 10rem;
}

$color-pri: #fbbf24;
$color-sec: #d97706;
$color-text: #1c212e;

@mixin max($value) {
  @media screen and (max-width: $value) {
    @content;
  }
}
