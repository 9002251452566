.row {
    --col-gap: 2.4rem;
    --row-gap: 2.4rem;
    display: flex;
    flex-wrap: wrap;
    margin: 0 calc(var(--col-gap) / 2 * -1) calc(var(--row-gap) * -1);
}

.col {
    width: 100%;
    padding: 0 calc(var(--col-gap) / 2);
    margin-bottom: var(--row-gap);

    &-11 {
        width: calc(11/12 * 100%);
    }

    &-10 {
        width: calc(10/12 * 100%);
    }

    &-9 {
        width: calc(9/12 * 100%);
    }

    &-8 {
        width: calc(8/12 * 100%);
    }

    &-7 {
        width: calc(7/12 * 100%);
    }

    &-6 {
        width: 50%;
    }

    &-5 {
        width: calc(5/12 * 100%);
    }

    &-4 {
        width: calc(4/12 * 100%);
    }

    &-3 {
        width: calc(3/12 * 100%);
    }

    &-2 {
        width: calc(2/12 * 100%);
    }

    &-1 {
        width: calc(1/12 * 100%);
    }
}