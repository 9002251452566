.sign {
  max-width: unset;
  width: 100%;
  margin: 0 -2rem;
  padding-top: 6rem;
  padding-bottom: 4.7rem;
  border-radius: 0;
  max-height: 59.3rem;
  vertical-align: bottom;
  overflow: hidden;
  //
  // position: absolute;
  // left: 0;
  // right: 0;
  // bottom: -100%;
  // opacity: 0;
  // transition: all 0.5s cubic-bezier(0.59, -0.17, 0.3, 1.67);
  &.is-active {
    opacity: 1;
    bottom: 0;
  }
  a.close-modal {
    display: none;
  }

  @include max(1440.98px) {
    height: 75vh;
    padding: 3rem 0;
  }
}
.blocker {
  padding: 0;
}
.sign-wrap {
  display: flex;
  justify-content: space-between;
  overflow: hidden auto;
  height: 100%;
  @include scrollbar;
  padding: 0 2rem;
  margin: 0 -2rem;
  @include max(767.98px) {
    flex-direction: column;
    align-items: center;
    row-gap: 2rem;
  }
}
.sign-content,
.sign-form {
  width: 48.8rem;
  @include max(575.98px) {
    width: 100%;
  }
}
.sign-content {
  .title {
    @include fz-36;
    color: #0a0a0a;
    line-height: 130%;
    margin-bottom: 1.2rem;
  }
}
.desc {
  @include fz-16;
  color: #4d4d4d;
  line-height: 150%;
}
.sign-form {
  .btn {
    width: 100%;
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  @include max(1440.98px) {
    .btn {
      margin: 2rem 0;
    }
  }
}
.password-group {
  display: flex;
  justify-content: space-between;
}
.forgot {
  color: #f59e0b;
  @include fz-14;
  font-weight: 600;
  line-height: 130%;
  @include transition;
  &:hover {
    color: $color-text;
  }
}
.line {
  position: relative;
  text-align: center;
  @include fz-16;
  line-height: 150%;
  &::before {
    content: " ";
    display: block;
    height: 0.1rem;
    width: calc(50% - 3rem);
    position: absolute;
    top: 50%;
    left: 0;
    background: #393d48;
  }
  &::after {
    content: " ";
    height: 0.1rem;
    width: calc(50% - 3rem);
    background: #393d48;
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
  }
}
.sign-width {
  display: flex;
  margin-top: 1.6rem;
  flex-direction: column;
  row-gap: 1.2rem;
  .form-control {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 1.2rem;
    padding: 1.2rem;
    @include transition;
    @include fz-16;
    line-height: 150%;
    &:hover {
      border-color: $color-pri;
    }
  }
}
.switch-sign {
  margin-bottom: 1rem;
  a {
    color: #f59e0b;
    font-weight: 700;
  }
}
