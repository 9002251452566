.prod-wrap {
  overflow: hidden;
  .club-img {
    @include flex-center;
    flex-wrap: nowrap;
    width: 9.7rem;
    padding: 1.5rem;
    height: 10.5rem;
    background: #fffbeb;
    border-radius: 0.6rem;
    @include transition;
    img {
      object-fit: contain;
      @include box(100%);
    }
  }

  .club {
    row-gap: 1rem;
    margin: 2.4rem -1.2rem;
    @include max(991.98px) {
      flex-wrap: wrap;
    }
    @include max(767.98px) {
      flex-wrap: nowrap;
    }
  }
  .club-item {
    padding: 0 1.2rem;

    width: 10%;
    @include flex-center;
    @include transition;
    &.is-active {
      .club-img {
        background: $color-pri;
      }
    }
    &:hover {
      .club-img {
        background: $color-pri;
      }
    }
    @include max(1199.98px) {
      width: calc(100% / 8);
    }
    @include max(991.98px) {
      width: calc(100% / 8);
    }
    @include max(767.98px) {
      width: fit-content;
    }
  }
  .product-list {
    flex-wrap: wrap;
    row-gap: 2.4rem;
  }
  .swiper {
    @include max(991.98px) {
      padding-bottom: 1.5rem;
    }
  }
}
.sort {
  @include flex-icenter;
  justify-content: space-between;
  margin-bottom: 2.4rem;
  column-gap: 1.6rem;
  @include max(640.98px) {
    column-gap: 1rem;
  }
  @include max(575.98px) {
    display: none;
    margin-bottom: 0;
    flex-direction: column;
    padding: 3rem 1.5rem;
  }
}
.sort-item {
  @include flex-icenter;
  column-gap: 1.6rem;
  @include max(640.98px) {
    column-gap: 1rem;
  }
  @include max(575.98px) {
    flex-direction: column;
    row-gap: 2rem;
  }
}
.sort-price,
.sort-brand {
  @include max(575.98px) {
    width: 100%;
  }
}
.sort-sale {
  border: 0.1rem solid #393d48;
  min-width: 19.1rem;
  position: relative;
  @include flex-icenter;
  .select2-container--default .select2-selection--single {
    border-radius: 0;
    background: transparent;
  }
  .select2 {
    min-width: 19.1rem;
    max-width: 28rem;
    @include max(767.98px) {
      max-width: 25rem;
    }
    @include max(640.98px) {
      max-width: 22rem;
    }
    @include max(575.98px) {
      max-width: unset;
    }
  }
  .select2-container .select2-selection--single .select2-selection__rendered {
    padding-left: 11.5rem;
    padding-right: 2rem;
    @include max(640.98px) {
      padding-left: 10rem;
    }
    @include max(575.98px) {
      padding-left: 11rem;
    }
  }
  .select2-container--default
    .select2-selection--single
    .select2-selection__arrow {
    right: 0.5rem;
  }
  @include max(575.98px) {
    margin-top: 2rem;
  }
}
.sort-content {
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 140%;
  position: absolute;
  @include flex-icenter;
  padding-left: 1rem;
  column-gap: 1rem;
  @include max(640.98px) {
    column-gap: 0.5rem;
    padding-left: 0.5rem;
  }
  @include max(575.98px) {
    column-gap: 1rem;
    padding-left: 1rem;
  }
}
.img-full {
  @include box(100%);
  object-fit: cover;
}
.img-group {
  display: flex;
  column-gap: 3.2rem;
  row-gap: 2rem;
  flex-wrap: wrap;
  margin-top: 4rem;
  margin-bottom: 4rem;
  img {
    width: calc(50% - 0.5 * 3.2rem);
    object-fit: cover;
    height: 100%;
  }
  @include max(767.98px) {
    column-gap: 2rem;
    img {
      width: calc(50% - 0.5 * 2rem);
    }
  }
  @include max(575.98px) {
    margin-top: 2rem;
    margin-bottom: 2rem;
    img {
      width: 100%;
    }
  }
}
.post {
  margin-top: 5.5rem;
  font-family: var(--font-pri);
  img {
    border-radius: 1.2rem;
  }
  p {
    line-height: 150%;
    @include fz-16;
    color: #393d48;
    margin: 2rem 0;
    & + img {
      margin-top: 2rem;
    }
    @include max(575.98px) {
      & + img {
        margin-top: 1rem;
      }
    }
  }
  h2 {
    margin-top: 2rem;
    margin-bottom: 1.6rem;
    color: #131825;
    font-weight: 700;
    font-size: 2.3rem;
    & + p {
      margin-top: 1.6rem;
    }
  }
  h3 {
    @include fz-16;
    font-weight: 700;
    margin-top: 1.2rem;
    letter-spacing: 0.016rem;
    color: #131825;
    & + p {
      margin-top: 1.6rem;
    }
  }
  figcaption {
    margin-bottom: 2rem;
    margin-top: 1.2rem;
    border-left: 0.2rem solid $color-pri;
    padding-left: 1.6rem;
    color: #5f626b;
    @include fz-14;
    line-height: 130%;
  }
}
.btn-sort {
  display: none;
  width: fit-content;
  margin: 2.4rem auto;
  text-transform: uppercase;
  min-width: 18rem;
  @include max(575.98px) {
    display: flex;
    column-gap: 1rem;
  }
}
.blocker {
  z-index: 100;
}

// ================== product detail ==============
.sale {
  margin-top: 3.7rem;
  overflow: hidden;
}
.prod-sale {
  @include flex;
  margin: 0 -1.6rem;
  @include max(1199.98px) {
    margin: 0 -1rem;
  }
  @include max(991.98px) {
    margin: 0;
    flex-direction: column;
    row-gap: 3rem;
  }
}
.prod-sale-item {
  padding: 0 1.6rem;
  width: 50%;
  .swiper-slide {
    cursor: pointer;
    @include flex-center;
    img {
      @include box(100%);
      display: block;
      object-fit: cover;
    }
  }
  @include max(1199.98px) {
    padding: 0 1rem;
  }
  @include max(991.98px) {
    padding: 0;
    width: 100%;
    .swiper {
      padding-bottom: 2rem;
    }
  }
}
.prod-gallery {
  .swiper-slide {
    max-height: 57.9rem;
    overflow: hidden;
  }
}
.prod-thumb {
  margin: 0 -1.2rem;
  .swiper-slide {
    padding: 0 1.2rem;
    width: fit-content;
  }
  .thumb-item {
    @include box(9rem);
    padding: 1.5rem 1rem;
    border-radius: 0.6rem;
    border: 0.1rem solid #94a3b8;
  }
  @include max(1199.98px) {
    margin: 0 -0.5rem;
    .swiper-slide {
      padding: 0 0.5rem;
    }
  }
}
.prod-modal {
  max-width: 90rem;
  height: fit-content;
  width: fit-content;
  padding: 1.5rem;
}
.prod-modal-inner {
  @include flex-center;
  overflow: hidden;
  height: 100%;
  img {
    height: 100%;
    object-fit: cover;
    display: block;
  }
}
.prod-sale-content {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  .product-price {
    color: #ef4444;
  }
  @include max(1199.98px) {
    row-gap: 1.5rem;
  }
}
.product-cate {
  @include fz-16;
  line-height: 150%;
  color: #393d48;
  strong {
    font-weight: 600;
  }
}
.product-size {
  span {
    @include fz-14;
    font-weight: 500;
    line-height: 150%;
    color: #393d48;
  }
}
.material-list {
  margin-top: 1.2rem;
  @include flex-icenter;
  flex-wrap: wrap;
  column-gap: 1.2rem;
  row-gap: 1.2rem;
}
.material-item {
  padding: 1.2rem;
  border-radius: 0.8rem;
  border: 0.1rem solid rgba(191, 191, 191, 0.5);
  @include box(4.6rem, 4.5rem);
  font-weight: 600;
  line-height: 150%;
  @include fz-14;
  color: #393d48;
  @include flex-center;
  @include transition;
  cursor: pointer;
  &.is-active {
    border-color: $color-pri;
  }
}
.product-material {
  .material-item {
    width: fit-content;
  }
}
.choose-size {
  color: #f59e0b;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 150%;
  text-decoration-line: underline;
  @include flex-icenter;
  column-gap: 0.4rem;
}
.size-heading {
  padding: 2.4rem;
  border-bottom: 0.1rem solid #cbd5e1;
  background: $color-pri;
  @include flex-icenter;
  justify-content: space-between;
  .title {
    @include fz-24;
    font-weight: 600;
    line-height: 150%;
  }
  a {
    @include flex-center;
  }
  @include max(575.98px) {
    padding: 2.4rem 1rem;
    column-gap: 1rem;
  }
}
.size-modal {
  max-width: 99.7rem;
  padding: 0;
  border-radius: 1.2rem;
  overflow: hidden;
  a.close-modal {
    display: none;
  }
}
.table {
  width: 100%;
  margin-bottom: 2.4rem;
  td,
  th {
    text-align: center;
    @include fz-20;
    line-height: 150%;
    color: #131825;
    padding: 1.2rem;
  }
  th {
    font-weight: 700;
  }
}
.size-box {
  padding: 1.6rem 2.4rem 2rem;
  p,
  li {
    @include fz-20;
    font-weight: 600;
    line-height: 150%;
    color: #393d48;
  }
  ul {
    list-style: disc;
    margin-left: 3.6rem;
    margin-top: 2.4rem;
    margin-bottom: 1.6rem;
  }
  @include max(575.98px) {
    padding: 2rem 1rem;
  }
}
thead {
  background: #bbcaf3;
}
tbody {
  tr {
    &:nth-of-type(even) {
      background: #f8f8f8;
    }
  }
}
.text-note {
  color: #ef4444;
}
.size-btn {
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
  .btn {
    min-width: 17.3rem;
  }
}
.check-item {
  position: relative;
  padding-left: 3.3rem;
  @include fz-14;
  color: #5f626b;
  line-height: 150%;
  &::before {
    @include box(2.4rem);
    left: 0;
    @include pseudo;
    @include bgImage(center, contain);
    background-image: url("../images/product/ico_check.svg");
  }
  & + & {
    margin-top: 1.4rem;
  }
}
.counter-box {
  max-width: 13.2rem;
  height: 4.8rem;
  @include flex-icenter;
  border-radius: 0.8rem;
  border: 0.1rem solid #eaeaea;
  button,
  span {
    height: 100%;
  }
  button {
    width: 4.2rem;
    padding: 0;
    @include flex-center;
    img {
      max-width: unset;
    }
  }
}
.counter-text {
  width: 4.8rem;
  text-align: center;
  @include flex-center;
  @include fz-16;
  line-height: 150%;
  color: #393d48;
}
.prod-action {
  @include flex-icenter;
  flex-wrap: wrap;
  row-gap: 1.5rem;
  column-gap: 2rem;

  .btn {
    min-width: 18rem;
  }
  @include max(1199.98px) {
    column-gap: 1rem;
  }

  @include max(575.98px) {
    justify-content: center;
    .wc-buy-now-btn {
      width: 100%;
    }
  }
}
.prod-require {
  .title {
    @include fz-20;
    font-weight: 700;
    line-height: 140%;
    text-align: center;
    text-transform: uppercase;
  }
}
.prod-contact {
  @include flex-icenter;
  flex-wrap: wrap;
  margin-top: 2rem;
  padding-top: 2rem;
  position: relative;
  &::before {
    @include pseudo;
    @include bgImage(center, contain);
    background-image: url("../images/product/line.png");
    top: 0;
    width: 100%;
    height: 0.1rem;
  }
  @include max(575.98px) {
    row-gap: 2rem;
  }
}
.prod-item {
  width: 51.5%;
  position: relative;
  @include fz-14;
  text-align: center;
  font-weight: 600;
  line-height: 150%;
  & + & {
    padding-left: 2.5rem;
    width: 48.5%;
    &::before {
      @include pseudo;
      @include bgImage(center, cover);
      background-image: url("../images/product/line_2.png");
      left: 0;
      height: 100%;
      width: 0.1rem;
    }
  }
  @include max(575.98px) {
    width: 100%;
    & + & {
      width: 100%;
      padding-left: 0;
      padding-top: 2rem;
      &::before {
        @include bgImage(center, contain);
        background-image: url("../images/product/line.png");
        top: 0;
        width: 100%;
        height: 0.1rem;
      }
    }
  }
}
.prod-call {
  @include flex-icenter;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 0.8rem;

  a {
    @include fz-24;
    @include flex-icenter;
    column-gap: 1.2rem;
    font-weight: 600;
    color: #ef4444;
    line-height: 150%;
  }
}
.prod-social {
  display: inline-flex;
  column-gap: 2.6rem;
  a {
    img {
      @include transition;
    }
    &:hover {
      img {
        transform: scale(1.1);
      }
    }
  }
}
.info-panel {
  display: none;
  @include fz-14;
  color: #5f626b;
  margin-top: 1.8rem;
  &.is-active {
    display: block;
  }
  .check-list {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .title {
    @include fz-14;
    font-weight: 600;
    line-height: 2.2rem;
  }
  hr {
    background: #393d48;
    display: block;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}
.prod-info {
  @include flex;
  overflow: hidden;
  margin: 6rem -1.6rem;
  @include max(1199.98px) {
    margin: 6rem -1rem;
  }
}
.prod-info-item {
  padding: 0 1.6rem;
  &:nth-child(1) {
    flex: 1;
  }
  &:nth-child(2) {
    width: 25%;
  }
  @include max(1199.98px) {
    padding: 0 1rem;
  }
  @include max(991.98px) {
    &:nth-child(2) {
      display: none;
    }
  }
}
.prod-tab {
  padding: 2.4rem;
  border-radius: 0.8rem;
  background: #f8fafc;
  @include max(575.98px) {
    padding: 2.4rem 1rem;
    padding-top: 1.4rem;
  }
}
.info-tab {
  border-bottom: 0.1rem solid #d9d9d9;
  @include flex-icenter;
  column-gap: 3.2rem;
  @include max(575.98px) {
    flex-direction: column;
    border-bottom: 0;
  }
}
.info-item {
  @include fz-16;
  color: #999999;
  line-height: 150%;
  font-weight: 400;
  width: fit-content;
  @include transition;
  cursor: pointer;
  border-bottom: 0.2rem solid transparent;
  border-radius: 0.1rem;
  &.is-active {
    font-weight: 600;
    color: #393d48;
    border-color: #393d48;
  }
  @include max(575.98px) {
    width: 100%;
    border-bottom: 0.2rem solid #d9d9d9;
    padding-bottom: 1rem;
    padding-top: 1rem;
  }
}
.disc-list {
  margin-left: 2.4rem;
  list-style: disc;
  margin-top: 2.4rem;
}
.ship-list {
  @include flex;
  column-gap: 3.2rem;
  row-gap: 1rem;
}
.ship-item {
  @include flex-icenter;
  column-gap: 0.8rem;
}
.prod-adv {
  border: 0.6rem;
  overflow: hidden;
  display: block;
  width: 100%;
  img {
    @include box(100%);
    object-fit: cover;
    @include transition;
  }
  &:hover {
    img {
      transform: scale(1.05);
    }
  }
}
.prod-relative {
  overflow: hidden;
  margin-bottom: 6rem;
  .product-list {
    flex-wrap: wrap;
    margin-top: 2.4rem;
    @include max(991.98px) {
      margin-top: 2.4rem;
    }
  }
}
.woo-variation-gallery-wrapper.woo-variation-gallery-thumbnail-position-bottom-bottom.woo-variation-gallery-product-type-variable.wvg-loaded {
  min-height: fit-content !important;
}
