.cart-wrap {
  margin-bottom: 14.8rem;
  @include max(575.98px) {
    margin-bottom: 10rem;
  }
}
.cart-box {
  display: flex;
  column-gap: 2rem;
  flex-wrap: wrap;
  @include max(1199.98px) {
    column-gap: 1.5rem;
  }
  @include max(991.98px) {
    flex-direction: column;
    row-gap: 3rem;
  }
}
.cart-pay {
  width: 31.49%;
  display: flex;
  flex-direction: column;
  row-gap: 1.6rem;
  @include max(1199.98px) {
    width: 100%;
  }
}
.cart-table {
  flex: 1;
  .table {
    border-spacing: 0;
    border-collapse: separate;
    margin-bottom: 0;
    thead {
      background: #f1f5f9;
    }
    th {
      padding: 2rem 0;
      // border-top: 0.1rem solid #d9d9d9;
      padding-right: 2rem;
      @include fz-14;
      font-weight: 700;
      line-height: 150%;
      color: #334155;
      text-align: left;
      &:first-child {
        padding-left: 2rem;
        border-top-left-radius: 1.2rem;
        border-left: 0.1rem solid #d9d9d9;
      }
      &:last-child {
        border-top-right-radius: 1.2rem;
        border-right: 0.1rem solid #d9d9d9;
        @include max(991.98px) {
          text-align: center;
        }
      }
      @include max(1199.98px) {
        padding-right: 1.2rem;
        &:first-child {
          padding-left: 1.2rem;
        }
      }
    }
    tbody {
      tr {
        &:nth-of-type(even) {
          background: transparent;
        }
        &:last-child {
          td {
            &:first-child {
              border-bottom-left-radius: 1.2rem;
              &::before {
                content: unset;
              }
            }
            &:last-child {
              border-bottom-right-radius: 1.2rem;
              &::before {
                content: unset;
              }
            }
          }
        }
        td {
          vertical-align: top;
          padding: 1.2rem 0;
          // border-bottom: 0.1rem solid #d9d9d9;
          position: relative;
          &:first-child {
            // border-left: 0.1rem solid #d9d9d9;
            padding-left: 2rem;
            // &::before {
            //   width: 2rem;
            //   height: 0.1rem;
            //   @include pseudo;
            //   background: #fff;
            //   left: 0;
            //   bottom: -0.1rem;
            // }
          }
          &:last-child {
            // border-right: 0.1rem solid #d9d9d9;
            padding-right: 2rem;
            // &::before {
            //   width: 2rem;
            //   height: 0.1rem;
            //   @include pseudo;
            //   background: #fff;
            //   right: 0;
            //   bottom: -0.1rem;
            // }
          }
          @include max(1199.98px) {
            // &:first-child {
            //   padding-left: 1.2rem;
            //   &::before {
            //     width: 1.2rem;
            //   }
            // }
            // &:last-child {
            //   padding-right: 1.2rem;
            //   &::before {
            //     width: 1.2rem;
            //   }
            // }
          }
        }
      }
    }
    @include max(991.98px) {
      th,
      td {
        min-width: 10rem;
      }
    }
  }
  @include max(991.98px) {
    width: 100%;
    flex: unset;
    overflow-x: auto;
  }
}
.cart-title {
  text-align: left;
  max-width: 17.7rem;
  .title {
    color: #000;
    @include fz-14;
    font-weight: 500;
    line-height: 150%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-bottom: 0.4rem;
  }
  color: #475569;
  @include fz-14;
  line-height: 150%;
  strong {
    font-weight: 600;
  }
}
.cart-img {
  padding: 1.4rem 2.9rem;
  @include flex-center;
  img {
    // min-width: 10rem;
    object-fit: cover;
    width: 10rem;
    border-radius: 0.6rem;
  }
  @include max(1199.98px) {
    padding: 0;
  }
}
.cart-main {
  padding: 2rem 0;
  display: flex;
  column-gap: 2.8rem;
  flex: 1;
  .product-price {
    @include fz-15;
    font-weight: 500;
    color: #475569;
    line-height: 150%;
    font-family: var(--font-pri);
  }
  .counter-box {
    height: 2.4rem;
    width: 6.4rem;
    border-radius: 0.4rem;
    border-color: #d9d9d9;
    button {
      width: 2.4rem;
      padding: 0.8rem;
      img {
        width: 0.8rem;
      }
    }
  }
  .counter-text {
    color: #131825;
    @include fz-12;
    flex: 1;
    width: 100%;
  }
  .icon-close {
    @include flex-center;
    width: 100%;
    cursor: pointer;
  }
  @include max(1199.98px) {
    column-gap: 1.5rem;
  }
}
.cart-item {
  display: flex;
  @include max(991.98px) {
    min-width: 27.4rem;
  }
}
.cart-payment {
  padding: 1.6rem;
  border-radius: 1.2rem;
  border: 0.1rem solid #d9d9d9;
  .title {
    @include fz-16;
  }
}
.payment-list {
  margin-top: 2.4rem;
  display: flex;
  row-gap: 2.4rem;
  flex-direction: column;
  @include max(1199.98px) {
    margin-top: 1.6rem;
    row-gap: 1.6rem;
  }
}
.payment-item {
  display: flex;
  justify-content: space-between;
  span {
    line-height: 150%;
    color: #393d48;
    @include fz-16;
    &:first-child {
      max-width: 22rem;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    &.cl-pri {
      color: $color-pri;
    }
  }
  .title {
    color: #2f343f;
    font-weight: 500;
    @include fz-16;
  }
  &:last-child {
    padding-top: 2.4rem;
    border-top: 0.1rem solid #bfbfbf80;
    span {
      font-weight: 600;
      text-transform: uppercase;
    }
  }
  @include max(1199.98px) {
    &:last-child {
      padding-top: 1.6rem;
    }
  }
}
.method-title {
  font-weight: 500;
  line-height: 150%;
}
.payment-method {
  .checkbox {
    margin-top: 2.4rem;
  }
  .checkmark {
    justify-content: space-between;
    span {
      color: #393d48;
      &:nth-child(2) {
        width: fit-content;
        flex: unset;
      }
    }
  }
  & > span {
    @include fz-12;
    line-height: 150%;
    color: #626974;
    display: block;
    margin-top: 1.2rem;
  }
  .checkbox-cod {
    padding-top: 2.4rem;
    border-top: 0.1rem solid #bfbfbf80;
  }
  @include max(1199.98px) {
    .checkbox {
      margin-top: 1.6rem;
    }
    .checkbox-cod {
      padding-top: 1.6rem;
    }
  }
}
.btn-box {
  .btn {
    width: 100%;
  }
}
// ============= information of customers
.info-box {
  column-gap: 3.2rem;
}
.cart-info {
  flex: 1;
  .title {
    @include fz-16;
    font-weight: 600;
    line-height: 3.2rem;
    letter-spacing: -0.032rem;
  }
}
.payment-form {
  margin-top: 2.4rem;
  .form-group {
    margin-bottom: 2.4rem;
  }
  textarea.form-control {
    height: 22.3rem;
  }
  .form-label {
    font-weight: 600;
  }
}

// ================= payment succeed ==========
.succeed-wrap {
  margin-top: 2.4rem;
  margin-bottom: 6rem;
}
.succeed-box {
  width: 66%;
  margin-left: auto;
  margin-right: auto;
  @include max(1199.98px) {
    width: 75%;
  }
  @include max(991.98px) {
    width: 85%;
  }
  @include max(767.98px) {
    width: 100%;
  }
}
.succeed-hero {
  text-align: center;
  .title {
    @include fz-20;
    font-weight: 600;
    line-height: 150%;
    color: #0f172a;
    margin-top: 1.6rem;
  }
  p {
    color: #475569;
  }
}
.succeed-heading {
  padding: 1.2rem 2rem;
  border-radius: 0.8rem 0.8rem 0 0;
  background: #f8fafc;
  margin-top: 3.6rem;
  .title {
    @include fz-24;
    font-weight: 600;
    line-height: 150%;
    color: #131825;
  }
}
.succeed-list {
  margin-top: 1.2rem;
  width: 100%;
}
.succeed-item {
  @include flex-icenter;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  row-gap: 1rem;
  &:not(:first-child) {
    margin-top: 1.2rem;
  }
  p {
    color: #475569;
    @include fz-16;
    font-weight: 600;
    line-height: 150%;
    min-width: 12rem;
    span {
      @include fz-15;
      font-weight: 500;
    }
  }
  strong {
    color: #000000;
    @include fz-16;
    font-weight: 600;
    line-height: 150%;
  }
  & > span {
    @include fz-16;
    color: #0f172a;
    line-height: 150%;
  }
  &.is-total {
    padding-top: 1.2rem;
    border-top: 0.1rem solid #f1f5f9;
    p {
      color: #0f172a;
      @include fz-16;
      font-weight: 700;
      &.cl-pri {
        color: $color-pri;
        min-width: unset;
      }
    }
  }
}
.is-border {
  padding-bottom: 1.2rem;
  border-bottom: 0.1rem solid #f1f5f9;
}
.succeed-prod {
  margin-top: 1.2rem;
}
.succeed-prop-item {
  display: flex;
  column-gap: 3.2rem;
  width: 100%;
  padding: 1.2rem;
  &:not(:first-child) {
    border-top: 0.1rem solid #e2e8f0;
  }
  @include max(767.98px) {
    column-gap: 2rem;
  }
  @include max(575.98px) {
    flex-direction: column;
    align-items: center;
  }
}
.succeed-calculator {
  display: flex;
  margin-top: 1.5rem;
  justify-content: space-between;
  & > p {
    @include fz-16;
    font-weight: 600;
    line-height: 150%;
    color: #041e42;
    span {
      color: $color-pri;
    }
  }
}
.succeed-content {
  flex: 1;
  .title {
    @include fz-16;
    font-weight: 600;
    line-height: 150%;
    color: #0f172a;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  @include max(575.98px) {
    width: 100%;
  }
}
.succeed-img {
  width: 12rem;
  height: 12rem;
  padding: 1.5rem;
  @include flex-center;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @include max(575.98px) {
    width: 10rem;
    // height: 10rem;
  }
}
.succeed-price {
  display: flex;
  column-gap: 1.2rem;
  .product-price {
    color: $color-pri;
    @include fz-20;
    font-family: var(--font-pri);
    font-weight: 700;
    line-height: 150%;
  }
}
.old-price {
  @include fz-16;
  color: #475569;
  line-height: 150%;
  text-decoration: line-through;
}
