.policy-panel {
  display: none;
  flex: 1;
  padding: 0 1.6rem;
  @include max(1199.98px) {
    padding: 0 1rem;
  }
  &.is-active {
    display: block;
  }
  .post {
    width: 100%;
    margin-top: 0;
    .title {
      @include fz-36;
      margin-bottom: 1.6rem;
      color: #131825;
    }
    .time {
      @include fz-16;
      line-height: 150%;
      & + h2 {
        margin-top: 2.4rem;
      }
    }
    p {
      margin: 0.6rem 0;
    }
    h2 {
      margin-top: 4.8rem;
    }
  }
  @include max(767.98px) {
    width: 100%;
    padding: 0;
  }
}
.policy {
  overflow: hidden;
  margin-bottom: 6rem;
}
.policy-wrap {
  display: flex;
  margin: 0 -1.6rem;
  @include max(1199.98px) {
    margin: 0 -1rem;
  }
  @include max(767.98px) {
    margin: 0;
    flex-direction: column;
    row-gap: 3rem;
  }
}
.policy-tab {
  width: 25%;
  padding: 0 1.6rem;
  .title {
    font-size: 2.3rem;
    color: #131825;
  }
  @include max(1199.98px) {
    padding: 0 1rem;
  }
  @include max(991.98px) {
    width: 30%;
  }
  @include max(767.98px) {
    width: 100%;
    padding: 0;
  }
}
.policy-list {
  margin-top: 2.4rem;
  width: 100%;
  display: block;
}
.policy-item {
  padding: 1.3rem 1.6rem;
  padding-left: 0;
  background-color: #fff;
  @include transition;
  border-radius: 0.4rem;
  width: 100%;
  display: block;
  @include fz-16;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: 0.016rem;
  color: #131825;
  cursor: pointer;
  a {
    width: 100%;
    display: inline-block;
  }
  &.is-active {
    background-color: $color-pri;
    display: block;
    padding-left: 1.3rem;
  }
  &:not(:first-child) {
    margin-top: 1.2rem;
  }
}
