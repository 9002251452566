.product-wrap {
  margin-top: 6rem;
  overflow: hidden;
}
.product-list {
  display: flex;
  margin: 0 -1.6rem;
  @include max(1199.98px) {
    margin: 0 -1rem;
  }

  @include max(991.98px) {
    flex-wrap: nowrap;
    justify-content: unset;
    margin: 0;
  }
}
.product-panel {
  display: none;
  width: 100%;
  &.is-active {
    display: block;
  }
  .swiper {
    padding-bottom: 0;
  }
  @include max(991.98px) {
    .swiper {
      padding-bottom: 4rem;
    }
  }
}
.product-slide {
  position: relative;
  padding: 0 5rem;
  margin: 0 -5rem;
  .swiper-button-next,
  .swiper-button-prev {
    display: none;
    @include transition;
  }
  .swiper-button-next {
    right: 1rem;
  }
  .swiper-button-prev {
    left: 1rem;
  }
  &:hover {
    .swiper-button-next,
    .swiper-button-prev {
      display: flex;
    }
  }
  @include max(1300px) {
    margin: 0 -3rem;
    padding: 0 3rem;
    .swiper-button-next {
      right: 1.5rem;
    }
    .swiper-button-prev {
      left: 1.5rem;
    }
  }
  @include max(800px) {
    margin: 0;
    padding: 0;
  }
}
.view-more {
  color: #d97706;
  @include fz-16;
  font-weight: 700;
  line-height: 150%;
  text-decoration-line: underline;
  @include transition;
  white-space: nowrap;
  &:hover {
    color: #37003c;
  }
}
.product-heading {
  @include flex-icenter;
  justify-content: space-between;
  margin-bottom: 2.4rem;
  gap: 1rem;
}
.product-tab {
  @include flex;
  align-items: center;
  gap: 1.6rem;
  margin-bottom: 2.4rem;
  @include max(1199.98px) {
    gap: 1rem;
  }
}
.product-tab-item {
  font-weight: 700;
  line-height: 150%;
  @include fz-14;
  color: #393d48;
  padding: 1.2rem 1.6rem;
  background: #e6e6e6;
  border-radius: 0.6rem;
  @include flex-center;
  cursor: pointer;
  @include transition;
  &.is-active {
    color: $color-text;
    background: $color-pri;
  }
  &:hover {
    color: $color-text;
    background: $color-pri;
  }
  @include max(1199.98px) {
    padding: 1rem;
  }

  @include max(767.98px) {
    padding: 0.8rem 0.4rem;
  }
}
.product-item {
  padding: 0 1.6rem;
  width: 25%;
  &.swiper-slide {
    height: unset;
  }
  @include max(1199.98px) {
    padding: 0 1rem;
  }
  @include max(991.98px) {
    width: calc(100% / 3);
    padding: 0;
  }
  @include max(767.98px) {
    width: 50%;
  }
  // @include max(575.98px) {
  //   width: 100%;
  // }
}
.product-inner {
  padding: 2rem 1.6rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  @include max(1199.98px) {
    padding: 2rem 1rem;
  }
}
.review {
  position: relative;
  display: inline-block;
}
.review-star {
  display: flex;
  gap: 0.4rem;
  li {
    @include box(1.2rem);
    background: url(../images/common/ico_star.svg) center/contain no-repeat;
  }
  &.is-active {
    min-width: max-content;
    li {
      background: url(../images/common/ico_star1.svg) center/contain no-repeat;
    }
  }
}
.review-star-bar {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  overflow: hidden;
}
.review-wrap {
  @include flex-icenter;
  column-gap: 1.2rem;
  span {
    @include fz-12;
    line-height: 150%;
    color: #999;
  }
}
.product-title,
.product-title a {
  font-weight: 600;
  line-height: 150%;
  @include fz-16;
  color: #1c212e;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  flex: 1;
  @include transition;
  &:hover {
    color: $color-pri;
  }
}
.product-content {
  @include flex;
  flex-direction: column;
  gap: 0.8rem;
  margin-top: 1.6rem;
  flex: 1;
}
.product-price {
  font-weight: 700;
  line-height: 140%;
  letter-spacing: -0.032rem;
  @include fz-24;
  color: #000;
  font-family: var(--font-three);
}
.product-img {
  position: relative;
  padding-top: 86.699%;
  display: block;
  overflow: hidden;
  &:hover {
    img {
      transform: scale(1.05);
    }
  }
  img {
    position: absolute;
    @include transition;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.swiper-pagination-bullet {
  @include box(1.2rem);
  background: #d9d9d9;
  opacity: 1;
  &.swiper-pagination-bullet-active {
    background-color: $color-pri;
  }
}
.product-label {
  position: absolute;
  top: 0;
}
.product-discount {
  @include flex;
  gap: 0.8rem;
  li {
    color: #fefefe;
    @include fz-14;
    line-height: 150%;
    font-family: var(--font-three);
    padding: 0.6rem 1.2rem;
    display: inline-block;
    border-radius: 0.8rem;
  }
  .is-discount {
    background: #b91c1c;
  }
  .is-new {
    background: #047857;
  }
  .is-soldout {
    background: #999;
  }
}
.h-cont-wrap {
  margin-top: 6rem;
}
.h-cont {
  border-radius: 1.6rem;
  background: $color-pri;
  overflow: hidden;
  @include flex-icenter;
  flex-wrap: wrap;
  min-height: 43.5rem;
  @include max(575.98px) {
    flex-direction: column;
    row-gap: 3rem;
    padding-bottom: 3rem;
  }
}
.h-cont-img {
  display: flex;
  flex: 1;
  img {
    @include box(100%);
    object-fit: cover;
    min-height: 43.5rem;
  }
  @include max(575.98px) {
    img {
      min-height: 35rem;
    }
  }
}
.h-cont-content {
  padding-left: 4rem;
  width: 35%;
  .title {
    @include fz-36;
    font-weight: 700;
    line-height: 150%;
    color: #0f172a;
    margin-bottom: 3.2rem;
  }
  .btn-sec {
    background: #92400e;
    color: #fff;
  }
  @include max(1199.98px) {
    padding-left: 1.6rem;
    width: 42%;
  }
  @include max(767.98px) {
    width: 50%;
  }
  @include max(575.98px) {
    width: 100%;
  }
}
.h-news {
  margin-top: 6rem;
  margin-bottom: 3rem;
  overflow: hidden;
}
.slide-wrap {
  .swiper {
    padding-bottom: 6rem;
  }
  margin: 0 -1.6rem;
  @include max(1199.98px) {
    margin: 0 -1rem;
  }
  @include max(575.98px) {
    margin: 0 -0.5rem;
  }
}
.swiper {
  @include max(991.98px) {
    padding-bottom: 6rem;
  }
}
.news-list {
  display: flex;
}
.news-item {
  width: 25%;
  padding: 0 1.6rem;
  @include max(1199.98px) {
    padding: 0 1rem;
  }
  @include max(991.98px) {
    width: calc(100% / 3);
  }
  @include max(767.98px) {
    width: 50%;
  }
  @include max(575.98px) {
    width: 100%;
    padding: 0;
  }
}
.news-img {
  border-radius: 0.4rem;
  overflow: hidden;
  position: relative;
  padding-top: 64.65%;
  display: block;
  img {
    position: absolute;
    @include transition;
    top: 0;
    @include box(100%);
    object-fit: cover;
  }
  &:hover {
    img {
      transform: scale(1.05);
    }
  }
}
.news-content {
  margin-top: 1.6rem;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}
.news-title,
.news-title a {
  @include fz-16;
  font-weight: 700;
  line-height: 150%;
  color: #1a1a1a;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  @include transition;
  &:hover {
    color: $color-pri;
  }
}
.news-tag {
  @include fz-14;
  font-weight: 700;
  line-height: 150%;
  color: #d97706;
}
.news-desc {
  color: #1e293b;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  @include fz-14;
  line-height: 150%;
}
.club-wrap {
  @include bgImage;
  margin-top: 6rem;
  padding: 4.2rem;
  background-image: url("../images/common/club_bg.jpg");
  position: relative;
  &::before {
    @include pseudo;
    @include box(100%);
    top: 0;
    left: 0;
    background: rgba(20, 19, 49, 0.5);
  }
  & > * {
    position: relative;
    z-index: 1;
  }
  .title {
    color: #fff;
    margin-bottom: 3.2rem;
  }
  @include max(575.98px) {
    padding: 4.2rem 0;
  }
}
.club {
  margin: 0 -1.6rem;
  @include flex;
  row-gap: 3.2rem;
  li {
    width: calc(100% / 6);
    padding: 0 1.6rem;
  }
  @include max(1199.98px) {
    margin: 0 -1rem;
    li {
      padding: 0 1rem;
    }
  }
  @include max(991.98px) {
    flex-wrap: nowrap;
    li {
      width: 20%;
    }
  }
  @include max(767.98px) {
    li {
      width: 25%;
    }
  }
  @include max(640.98px) {
    margin: 0 -0.5rem;
    li {
      width: calc(100% / 3);
      padding: 0 0.5rem;
    }
  }
  @include max(575.98px) {
    // li {
    //   width: 50%;
    // }
  }
}
.club-item {
  // @include flex-center;
  display: block;

  &:hover {
    .club-title {
      color: $color-pri;
    }
    .club-img {
      img {
        transform: scale(1.05);
      }
    }
  }
}
.club-title {
  @include fz-24;
  font-weight: 700;
  line-height: 150%;
  color: #fff;
  text-align: center;
  @include transition;
  @include max(575.98px) {
    @include fz-16;
  }
}
.club-img {
  padding: 1.5rem;
  border-radius: 1.5rem;
  background: rgba(255, 255, 255, 0.7);
  overflow: hidden;
  margin-bottom: 0.4rem;
  @include flex-center;
  span {
    position: relative;
    padding-top: 100%;
    display: block;
    @include box(100%);
  }
  img {
    @include transition;
    position: absolute;
    @include box(100%);
    object-fit: contain;
    top: 0;
    left: 0;
  }
}
.product-hot {
  padding-top: 6.8rem;
  padding-bottom: 3rem;
  @include bgImage;
  position: relative;
  &::before {
    @include pseudo;
    @include box(100%);
    top: 0;
    left: 0;
    background: rgba(27, 38, 77, 0.71);
  }
  & > * {
    position: relative;
    z-index: 1;
  }
  background-image: url("../images/common/hot_bg.jpg");
  .product-inner {
    background: #fff;
    border-radius: 0.6rem;
  }
  .title {
    color: #fff;
    margin-bottom: 3.2rem;
  }
  .product-list {
    margin: 0;
    flex-wrap: nowrap;
  }
  @include max(1199.98px) {
    .product-item {
      padding: 0 1rem;
    }
  }
  @include max(575.98px) {
    .product-item {
      padding: 0 0.5rem;
    }
  }
}
.intro {
  margin: 6rem 0;
  .title {
    color: #fefefe;
  }
  .product-img {
    padding-top: 64.326%;
    border-radius: 1.6rem;
    &::before {
      @include pseudo(1);
      @include box(100%);
      top: 0;
      left: 0;
      opacity: 0.5;
      background: #242424;
    }
  }

  .product-label {
    z-index: 2;
    width: 100%;
    height: 100%;
    top: 0;
    text-align: center;
    padding: 0 1.5rem;
    @include flex-center;
    .title {
      @include transition;
    }
    &:hover {
      .title {
        color: $color-pri;
      }
    }
  }
  .product-item {
    width: calc(100% / 3);
  }
  .product-list {
    row-gap: 3.2rem;
    flex-wrap: wrap;
    margin: 0;
  }

  @include max(991.98px) {
    .product-list {
      flex-wrap: wrap;
    }
    .product-item {
      padding: 0 1rem;
    }
    .swiper {
      padding-bottom: 0;
    }
  }
  @include max(767.98px) {
    .swiper {
      padding-bottom: 6rem;
      overflow: hidden;
    }
    .product-list {
      flex-wrap: nowrap;
    }
    .product-item {
      width: 50%;
    }
  }
  @include max(575.98px) {
    .product-item {
      width: 100%;
      padding: 0;
    }
  }
}
.banner {
  position: relative;
  @include max(991.98px) {
    .swiper {
      padding-bottom: 0;
    }
  }
}

.banner-img {
  position: relative;
  height: calc(100vw * (689 / 1728));
  max-height: 68.9rem;
  display: block;
  img {
    // @include box(100%);
    object-fit: cover;
    position: absolute;
    width: 100%;
    height: auto;
  }
  @include max(575.98px) {
    // min-height: 35rem;
    height: 100%;
    img {
      position: relative;
    }
  }
}
.swiper-button-prev,
.swiper-button-next {
  @include box(4rem);
  background-color: $color-sec;
  border-radius: 50%;

  @include transition;
  &::after {
    content: unset;
  }
  &.swiper-button-disabled {
    background-color: rgba($color-sec, 0.3);
    opacity: 1;
  }
  @include max(800px) {
    display: none;
  }
}
.swiper-button-next {
  right: -5rem;
  @include max(1300px) {
    right: -1.5rem;
  }
}
.swiper-button-prev {
  left: -5rem;
  @include max(1300px) {
    left: -1.5rem;
  }
}
.banner-prev,
.banner-next {
  bottom: 11rem;
  @include box(5rem);
  top: unset;
  border: 0.2rem solid #fff;
  background-color: transparent;
  &.swiper-button-disabled {
    border: 0.1rem solid rgba(255, 255, 255, 0.2);
    background-color: transparent;
  }
  @include max(1300px) {
    bottom: 5rem;
  }
  @include max(800px) {
    display: flex;
  }
  @include max(767.98px) {
    display: none;
  }
}
.banner-next {
  right: calc((100vw - 124.6rem) / 2);
  @include max(1300px) {
    right: 0;
  }
}
.banner-prev {
  left: unset;
  right: calc(((100vw - 124.6rem) / 2) + 8rem);
  @include max(1300px) {
    right: 8rem;
  }
}
.main {
  section {
    &:first-child {
      margin-top: 0 !important;
    }
  }
}
