.breadcrumb-wrap {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.breadcrumb {
  @include flex-icenter;
  flex-wrap: wrap;
  row-gap: 1rem;
}
.breadcrumb-item {
  color: #5f626b;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 150%;
  position: relative;
  a {
    @include transition;
  }
  & + & {
    padding-left: 0.5rem;
    &::before {
      padding-right: 0.5rem;
      color: #5f626b;
      content: "/";
    }
  }
  &:hover {
    a {
      color: $color-pri;
    }
  }
  &.is-current {
    a {
      pointer-events: none;
      cursor: default;
    }
    &:hover {
      a {
        color: #5f626b;
      }
    }
  }
}
