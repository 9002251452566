.news-outer {
  overflow: hidden;
}
.news-wrap {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1.6rem;
  margin-top: 2.4rem;
  @include max(1199.98px) {
    margin-right: -1rem;
    margin-left: -1rem;
  }
  @include max(991.98px) {
    row-gap: 2.4rem;
  }
}
.news-main {
  width: 75%;
  .news-list {
    margin: 0 -1.2rem;
    flex-wrap: wrap;
    row-gap: 2.4rem;
    @include max(1199.98px) {
      margin: 0 -1rem;
    }
  }
  .news-item {
    padding: 0 1.2rem;
    width: 50%;
    display: flex;
    column-gap: 1.2rem;
    &:nth-child(1) {
      width: 100%;
      column-gap: 3.2rem;
      .news-img {
        width: 42.5%;
        padding-top: 29.978%;
      }
      .news-title,
      .news-title a {
        @include fz-24;
        line-height: 3.2rem;
        color: #393d48;
        &:hover {
          color: $color-pri;
        }
      }
      .news-desc {
        -webkit-line-clamp: 8;
        line-clamp: 8;
        color: #5f626b;
        @include fz-16;
      }
      @include max(991.98px) {
        column-gap: 1rem;
      }
    }
  }
  .news-img {
    width: 40%;
    padding-top: 22.276%;
  }
  .news-content {
    flex: 1;
    margin-top: 0;
  }

  @include max(1199.98px) {
    padding: 0 1rem;
  }
  @include max(991.98px) {
    width: 100%;
  }

  @include max(575.98px) {
    .news-list {
      margin: 0 -0.75rem;
    }
    .news-item {
      flex-direction: column;
      row-gap: 1.2rem;
      padding: 0 0.75rem;
      &:nth-child(1) {
        .news-img {
          width: 100%;
          padding-top: 79.475%;
        }
      }
    }
    .news-img {
      width: 100%;
      padding-top: 61.83%;
    }
  }
}
.news-suggest {
  width: 25%;
  .news-list {
    flex-direction: column;
  }
  .news-item {
    width: 100%;
    padding-top: 1.6rem;
    padding-right: 0;
    padding-left: 0;
    &:not(:last-child) {
      padding-bottom: 1.6rem;
      border-bottom: 0.1rem solid #e0e0e0;
    }
  }
  .news-content {
    margin-top: 0;
  }
  @include max(991.98px) {
    width: 100%;
    .news-list {
      flex-direction: row;
      flex-wrap: wrap;
      column-gap: 1rem;
    }
    .news-item {
      width: calc(50% - 0.5 * 1rem);
      padding-bottom: 1.6rem;
      border-bottom: 0.1rem solid #e0e0e0;
    }
  }

  @include max(575.98px) {
    .news-list {
      flex-direction: column;
    }
    .news-item {
      width: 100%;
    }
  }
}
.news-main,
.news-suggest {
  padding: 0 1.6rem;
  @include max(1199.98px) {
    padding: 0 1rem;
  }
}
.news-box {
  margin-top: 6rem;
  overflow: hidden;
  &:last-child {
    margin-bottom: 6rem;
  }
  .news-list {
    margin: 0 -1.6rem;
    flex-wrap: wrap;
    row-gap: 2rem;
    justify-content: center;
    @include max(1199.98px) {
      margin: 0 -1rem;
    }
  }
  @include max(991.98px) {
    .news-list {
      margin: 0 -0.75rem;
    }
    .news-item {
      width: 25%;
      padding: 0 0.75rem;
    }
  }
  @include max(767.98px) {
    .news-item {
      width: 50%;
    }
  }
}

// ================== news detail ===============
.detail-heading {
  width: 75%;
  .title {
    @include fz-36;
    color: #131825;
  }
  .news-desc {
    @include fz-16;
  }
  @include max(991.98px) {
    width: 100%;
  }
}
.news-info {
  display: flex;
  margin-top: 2.4rem;
  flex-wrap: wrap;
  li {
    display: flex;
    align-items: center;
    column-gap: 0.8rem;
    &:nth-child(1) {
      span {
        @include fz-16;
        font-weight: 700;

        letter-spacing: 0.016rem;
      }
    }
    span {
      @include fz-14;
      color: #393d48;
      line-height: 150%;
    }
    &:not(:last-child) {
      padding-right: 1.6rem;
    }
    &:not(:first-child) {
      border-left: 0.1rem solid #d9d9d9;
      padding-left: 1.6rem;
    }
  }
}
.detail-wrap {
  .post {
    margin-top: 0;
  }
  .news-wrap {
    margin-top: 4rem;
  }
  .news-group {
    &:not(:first-child) {
      margin-top: 6rem;
    }
  }
  .prod-adv {
    margin-top: 6rem;
    @include max(991.98px) {
      display: none;
    }
  }
}
.news-category {
  margin-top: 1.6rem;
  li {
    @include flex-icenter;
    justify-content: space-between;
    padding: 0.8rem 0;
    span {
      font-weight: 500;
      color: #393d48;
    }
  }
  .news-title,
  .news-title a {
    @include fz-14;
    color: #d97706;
    &:hover {
      color: $color-text;
    }
  }
}
