.footer-wrap {
  // @include bgImage(left center);
  // background-image: url("../images/common/footer_bg.jpg");
  background: #37003c;
  padding-top: 9rem;
  padding-bottom: 8.8rem;
  overflow: hidden;
  @include max(575.98px) {
    padding: 8rem 0;
  }
}
.footer {
  @include flex;
  margin: 0 -1.6rem;
  @include max(1199.98px) {
    margin: 0 -0.5rem;
  }
  @include max(991.98px) {
    row-gap: 3rem;
  }
}
.footer-list {
  width: 25%;
  padding: 0 1.6rem;
  .nav-logo {
    margin-bottom: 1.6rem;
  }
  .menu {
    flex-direction: column;
    align-items: flex-start;
  }
  .menu-item {
    padding: 0.8rem 0;
  }
  .menu-item,
  .menu-link {
    @include fz-16;
    font-weight: 400 !important;
    color: #fff;
    &:hover {
      font-weight: 400;
    }
  }
  .menu-link {
    &:hover {
      text-decoration: underline;
    }
  }
  @include max(1199.98px) {
    padding: 0 0.5rem;
  }
  @include max(991.98px) {
    width: calc(100% / 3);
  }
  @include max(767.98px) {
    width: 50%;
  }
  @include max(575.98px) {
    width: 100%;
  }
}
.footer-title {
  @include fz-18;
  color: #fefefe;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 1.6rem;
}
.social-list {
  .menu {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 1.6rem;
  }
  .menu-item {
    padding: 0.4rem 0;
  }
  .menu-link {
    @include flex-center;
    img {
      @include transition;
    }
    &:hover {
      img {
        transform: scale(1.1);
      }
    }
  }

  @include max(1199.98px) {
    .menu-item {
      width: fit-content;
    }
  }
}
.footer-map {
  overflow: hidden;
  border-radius: 0.6rem;
  width: 100%;
  height: 17.3rem;
  margin-top: 2.5rem;
}
.copy-right {
  @include flex-icenter;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 1rem;
  column-gap: 1rem;
  color: #fff;
  @include fz-14;
  font-weight: 600;
  line-height: 150%;
  margin-top: 4rem;
  padding-top: 4rem;
  border-top: 0.1rem solid #ffffff33;
  p {
    @include flex-icenter;
    column-gap: 0.6rem;
    flex-wrap: wrap;
    row-gap: 0.6rem;
    a {
      display: flex;
      align-items: center;
    }
  }
}
.contact-fixed {
  position: fixed;
  bottom: 30%;
  z-index: 19;
  background: #fbbf24;
  right: 0;
  border-radius: 0.4rem 0 0 0.4rem;
  .menu {
    flex-direction: column;
    width: fit-content;
  }
  img {
    width: 4rem;
  }
  .ico-zalo {
    width: 3rem;
  }
  .menu-item {
    padding: 0;
  }
  .menu-link {
    padding: 0.5rem 0;
    @include flex-center;
    img {
      @include transition;
    }
    &:hover {
      img {
        transform: scale(1.1);
      }
    }
  }
}
.backtotop {
  width: 4rem;
  height: 4rem;
  border-radius: 0.6rem;
  background-color: $color-pri;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  position: fixed;
  bottom: 7rem;
  right: 4rem;
  z-index: 12;
  transform: translateX(4rem);
  opacity: 0;
  transition: 0.5s linear;
  cursor: pointer;
  visibility: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  @include max(768px) {
    right: 0.5rem;
  }
  &.is-active {
    visibility: visible;
    transform: translateX(0);
    opacity: 1;
  }
  .triangle {
    display: block;
    width: 0;
    height: 0;
    z-index: 2;
    border: 8px solid transparent;
    border-bottom: 6px solid #fff;
    margin-top: -6px;
    border-radius: 5px;
    &:nth-child(1) {
      animation: BTT 1.5s linear 0.6s infinite;
    }
    &:nth-child(2) {
      animation: BTT 1.5s linear 0.3s infinite;
    }
    &:nth-child(3) {
      animation: BTT 1.5s linear 0s infinite;
    }
  }
  &:hover {
    @media only screen and (min-width: 901px) {
      visibility: visible;
      opacity: 1;
      transform: translateX(0);
    }
  }
}
@keyframes BTT {
  0% {
    opacity: 0.4;
  }

  33% {
    opacity: 1;
  }

  66% {
    opacity: 0.4;
  }

  100% {
    opacity: 0.5;
  }
}
