.banner-sec {
  .banner-img {
    height: 35rem;
    position: relative;
    &::before {
      @include pseudo(1);
      @include box(100%);
      opacity: 0.5;
      background: #393d48;
    }
  }
}
.banner-content {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 2;
  @include flex-center;
  padding: 1.5rem;
  text-align: center;
  flex-direction: column;
  .title {
    @include fz-48;
    line-height: 110%;
    color: #fff;
  }
  .breadcrumb-wrap {
    margin-top: 1.2rem;
    margin-bottom: 0;
  }
  .breadcrumb-item {
    color: #fff;
    &.is-current {
      &:hover {
        a {
          color: #fff;
        }
      }
    }
  }
  .breadcrumb-item + .breadcrumb-item::before {
    color: #fff;
  }
}
.ab-wrap {
  display: flex;
  padding-top: 4rem;
  padding-bottom: 10rem;
  @include max(767.98px) {
    flex-direction: column-reverse;
    row-gap: 3rem;
  }
}
.ab-img {
  width: 48.7%;
  border-radius: 0.6rem;
  overflow: hidden;
  img {
    @include box(100%);
    object-fit: cover;
  }
  @include max(767.98px) {
    width: 100%;
    // display: none;
    max-height: 50rem;
  }
}
.ab-content {
  flex: 1;
  border-radius: 1.2rem;
  border: 0.1rem solid #d9d9d9;
  background: #fff;
  padding: 4.8rem;
  overflow: hidden;
  margin-left: -10rem;
  margin-top: 6.3rem;
  margin-bottom: 3.8rem;
  .title {
    @include fz-48;
    font-weight: 600;
    line-height: 140%;
    letter-spacing: -0.144rem;
    color: #0a0a0a;
    margin-bottom: 1.6rem;
  }
  .btn {
    margin-top: 3.2rem;
    width: 20rem;
  }
  p {
    line-height: 150%;
    letter-spacing: -0.048rem;
    color: #5f626b;
  }
  @include max(1199.98px) {
    padding: 3rem 2rem;
  }
  @include max(767.98px) {
    margin-left: 0;
    margin-top: 0;
    margin-bottom: 0;
  }
  @include max(575.98px) {
    padding: 3rem 1.5rem;
  }
}
.exp-list {
  margin-top: 4.8rem;
  display: flex;
  align-items: center;
  @include max(575.98px) {
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: center;
  }
}
.exp-numb {
  display: flex;
  span {
    color: $color-pri;
    font-size: 6.4rem;
    font-weight: 600;
    line-height: 140%;
    letter-spacing: -0.192rem;
    @include max(1199.98px) {
      font-size: 5rem;
    }
  }
  sup {
    color: $color-pri;
    font-weight: 600;
    letter-spacing: -0.192rem;
    font-size: 4.8rem;
    line-height: 100%;
    @include max(1199.98px) {
      font-size: 4rem;
    }
  }
}
.exp-item {
  padding: 2.4rem 2rem 2.4rem 4.8rem;
  @include fz-16;
  line-height: 150%;
  letter-spacing: -0.048rem;
  color: #393d48;
  border: 0.1rem solid #d9d9d9;
  &:nth-child(odd) {
    border-radius: 0.6rem;
  }
  @include max(1440.98px) {
    padding-left: 3rem;
  }
  @include max(1199.98px) {
    padding: 2.4rem 2rem;
  }
  @include max(991.98px) {
    padding: 2.4rem 1rem;
  }
  @include max(575.98px) {
    width: calc(50% - 0.5 * 2rem);
  }
}
.ab-study {
  padding-top: 8rem;
  padding-bottom: 17.7rem;
  @include bgImage;
  background-image: url("../images/about/ab_img_1.jpg");
  position: relative;
  text-align: center;

  &::before {
    @include pseudo;
    @include box(100%);
    opacity: 0.8;
    background: #393d48;
    top: 0;
    left: 0;
  }
  & > .container {
    position: relative;
    z-index: 1;
    max-width: 103.8rem;
  }
  .title {
    @include fz-48;
    line-height: 140%;
    letter-spacing: -0.144rem;
    color: #fff;
    margin-bottom: 1.6rem;
  }
  p {
    @include fz-16;
    color: #fefefe;
    line-height: 150%;
    letter-spacing: -0.048rem;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    line-clamp: 5;
    -webkit-box-orient: vertical;
  }
}
.ab-core {
  margin-top: -10.7rem;
  margin-bottom: 10rem;
  position: relative;
  z-index: 1;
}
.core-list {
  display: flex;
  column-gap: 3.2rem;
  @include max(1199.98px) {
    column-gap: 2rem;
  }
  @include max(575.98px) {
    row-gap: 2rem;
    column-gap: 1rem;
    flex-wrap: wrap;
    justify-content: center;
  }
}
.core-item {
  padding: 4.8rem;
  @include flex-center;
  flex-direction: column;
  row-gap: 4.8rem;
  border-radius: 1.2rem;
  border: 0.1rem solid #d9d9d9;
  background: #f8fafc;
  width: calc(100% / 3 - (1 - 1 / 3) * 3.2rem);
  @include transition;
  svg {
    path {
      @include transition;
    }
  }
  &:hover {
    background: $color-pri;
    svg {
      path {
        stroke: #393d48;
      }
    }
  }
  .title {
    color: #131825;
    font-weight: 600;
    line-height: 140%;
    letter-spacing: -0.072rem;
    text-transform: uppercase;
    @include fz-24;
    margin-bottom: 0.8rem;
  }
  p {
    @include fz-16;
    line-height: 150%;
    letter-spacing: -0.048rem;
    color: #393d48;
  }
  @include max(1199.98px) {
    padding: 3rem 2rem;
    row-gap: 3rem;
    width: calc(100% / 3 - (1 - 1 / 3) * 2rem);
  }
  @include max(991.98px) {
    padding: 3rem 1rem;
  }
  @include max(575.98px) {
    width: calc(50% - 0.5 * 1rem);
  }
}
.core-content {
  text-align: center;
}
