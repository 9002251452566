.select2-dropdown {
  min-width: 21rem;
}
.select2 {
  width: fit-content !important;
  max-width: 25rem;
  @include max(767.98px) {
    max-width: 18rem;
  }
  @include max(640.98px) {
    max-width: 16rem;
  }
  @include max(575.98px) {
    max-width: unset;
    width: 100% !important;
  }
}
.select2-results__options {
  @include scrollbar;
}
.select2-container--default .select2-selection--single {
  border: 0;
  background: #e6e6e6;
  border-radius: 0.6rem;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__rendered,
.select2-container--default
  .select2-selection--single
  .select2-selection__placeholder {
  color: #393d48;
  @include fz-14;
  font-weight: 600;
  font-family: var(--font-pri);
  line-height: 4.5rem;
}
.select2-container .select2-selection--single {
  height: 4.5rem;
}
.select2-container .select2-selection--single .select2-selection__rendered {
  padding-left: 1.6rem;
  padding-right: 3.6rem;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 4.3rem;
  right: 1rem;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  border: 0;
  @include bgImage;
  background-image: url("../images/product/ico_arrow.svg");
  width: 1rem;
  height: 0.6rem;
  display: block;
  @include transition;
}
.select2-container--default
  .select2-results__option--highlighted.select2-results__option--selectable {
  background-color: $color-pri;
}
.select2-results__option {
  color: #393d48;
  @include fz-14;
  font-weight: 600;
  font-family: var(--font-pri);
  line-height: 150%;
}
.select2-dropdown {
  border-radius: 0.6rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0 0.2rem 0.8rem 0;
  overflow: hidden;
}
.select2-container--default.select2-container--open
  .select2-selection--single
  .select2-selection__arrow
  b {
  transform: rotate(180deg);
  border: 0;
}
.form-group {
  display: flex;
  flex-direction: column;
  row-gap: 0.8rem;
  margin-bottom: 1.6rem;
}
.form-password {
  position: relative;
}
.icon-password {
  position: absolute;
  right: 2.8rem;
  top: 50%;
  transform: translateY(-50%);
  @include flex-center;
  height: fit-content;
  cursor: pointer;
  @include transition;
  img {
    @include transition;
  }
  .is-hide {
    display: none;
  }
  &.is-active {
    .is-show {
      display: none;
    }
    .is-hide {
      display: block;
    }
  }
}
.form-label,
.woocommerce-form-row label {
  @include fz-16;
  font-family: var(--font-pri);
  font-weight: 700;
  line-height: 150%;
  letter-spacing: 0.016rem;
  color: #393d48;
  span {
    color: #d22522;
  }
}
.form-control,
.woocommerce-Input {
  font-family: var(--font-pri);
  font-weight: 500;
  line-height: 130%;
  height: 4.8rem;
  width: 100%;
  @include fz-14;
  color: #393d48;
  padding: 1.5rem;
  border-radius: 0.8rem;
  border: 0.1rem solid #d9d9d9;
  box-shadow: 0;
  @include transition;
  &:focus {
    box-shadow: 0;
    outline: none;
    border-color: $color-pri;
  }
  &::placeholder {
    color: #999;
  }
}
.checkbox {
  cursor: pointer;
  position: relative;
}
.checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  display: flex;
  column-gap: 0.8rem;
  cursor: pointer;
  span {
    @include fz-14;
    line-height: 130%;
    display: flex;
    align-items: flex-start;
    &:nth-child(2) {
      flex: 1;
    }
  }
  img {
    opacity: 1;
    @include transition;
    &.is-checked {
      display: none;
      opacity: 0;
    }
  }
}

.checkbox input:checked ~ .checkmark {
  img {
    display: none;
    opacity: 0;
    &.is-checked {
      display: block;
      opacity: 1;
    }
  }
}

.checkbox input:checked ~ .checkmark:after {
  display: block;
}

.form-wrap {
  display: flex;
  column-gap: 2.8rem;
  .form-group {
    width: calc(50% - 0.5 * 2.8rem);
  }
  @include max(767.98px) {
    column-gap: 1.6rem;
    .form-group {
      width: calc(50% - 0.5 * 1.6rem);
    }
  }
  @include max(575.98px) {
    flex-direction: column;
    .form-group {
      width: 100%;
    }
  }
}
textarea.form-control {
  height: 18.3rem;
}
.woocommerce-button {
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 2rem;
  color: #1c212e !important;
  height: 4.4rem;
  padding: 1.2rem 3.8rem;
  font-family: var(--font-pri);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.8rem;
  position: relative;
  overflow: hidden;
  @include transition;
  background-color: $color-pri !important;
  &:hover {
    background-color: #37003c !important;
    color: #fff !important;
  }
}
.form-row {
  margin-bottom: 1.6rem !important;
}

.woocommerce .woocommerce-form-login .woocommerce-form-login__rememberme {
  margin-top: 0.8rem;
}
.woocommerce-form-register {
  .woocommerce-button {
    margin-top: 1.6rem !important;
  }
}
.u-columns {
  h2 {
    font-size: 2rem;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: 0.02rem;
    color: #131825;
    margin-bottom: 3.2rem;
  }
}
.woocommerce-form {
  min-height: 33.6rem;
}
