.contact-address {
  width: 40%;
  padding: 8rem 3.2rem;
  overflow: hidden;
  border-radius: 1.2rem;
  @include bgImage;
  background-image: url("../images/about/contact_1.jpg");
  position: relative;
  &::before {
    @include box(100%);
    @include pseudo;
    left: 0;
    top: 0;
    background: #393d48;
    opacity: 0.5;
  }
  & > * {
    position: relative;
    z-index: 2;
  }
  .title {
    color: #fff;
    @include fz-36;
    margin-bottom: 0.8rem;
  }
  .desc {
    color: #fefefe;
    @include fz-16;
  }
  .menu {
    justify-content: start;
    column-gap: 0.8rem;
    flex-wrap: wrap;
  }
  @include max(1199.98px) {
    padding: 5rem 2rem;
    .menu {
      flex-direction: row;
    }
    .menu-item {
      width: fit-content;
      padding: 0;
    }
  }
  @include max(991.98px) {
    width: 100%;
  }
}
.contact-box {
  display: flex;
  column-gap: 3.2rem;
  margin-bottom: 10rem;
  @include max(1199.98px) {
    column-gap: 2rem;
  }
  @include max(991.98px) {
    flex-direction: column;
    row-gap: 3rem;
  }
}
.contact-form {
  flex: 1;
  .title {
    @include fz-20;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: 0.02rem;
    color: #131825;
    margin-bottom: 3.2rem;
  }
  .btn {
    min-width: 25rem;
  }
}
.contact-info {
  margin: 2.4rem 0;
}
.contact-item {
  @include fz-16;
  display: flex;
  flex-direction: column;
  color: #fefefe;
  strong {
    font-weight: 700;
    font-family: var(--font-four);
    display: inline-block;
    margin-bottom: 0.8rem;
    color: #fff;
  }
  &:not(:first-child) {
    margin-top: 1.6rem;
  }
}
.title-social {
  @include fz-16;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: 0.016rem;
  color: #fff;
  margin-bottom: 0.8rem;
}
.contact-map {
  iframe {
    width: 100%;
    height: 60rem;
  }
}
