/*------------------------------------------------------------
	Clearfix
------------------------------------------------------------*/
@mixin clear {
  *zoom: 1;
  &:after {
    display: block;
    content: "";
    clear: both;
  }
}

/*------------------------------------------------------------
	Font
------------------------------------------------------------*/
@mixin mincyo() {
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3",
    "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
@mixin mincho() {
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3",
    "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}

@mixin monstfont() {
  font-family: "Montserrat", sans-serif;
}

/*------------------------------------------------------------
	Media
------------------------------------------------------------*/
@mixin max($point) {
  @media screen and (max-width: $point) {
    @content;
  }
}
@mixin min($point) {
  @media screen and (min-width: $point) {
    @content;
  }
}
@mixin sp {
  @media screen and (max-width: #{$breakpoint1}) {
    @content;
  }
}
@mixin pc {
  @media screen and (min-width: #{$breakpoint2}) {
    @content;
  }
}
@mixin min-sp {
  @media screen and (min-width: #{$point}) {
    @content;
  }
}

@mixin tab-land {
  @media screen and (max-width: 1024px) {
    @content;
  }
}

@mixin tab-port {
  @media screen and (max-width: 960px) {
    @content;
  }
}
@mixin tab-mini {
  @media screen and (max-width: 768px) {
    @content;
  }
}

/*------------------------------------------------------------
	Flex Box
------------------------------------------------------------*/
@mixin flex {
  display: flex;
  flex-wrap: wrap;
}
@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
@mixin flex-icenter {
  display: flex;
  align-items: center;
}
@mixin flex-jcenter {
  display: flex;
  justify-content: center;
}
@mixin flex-jbetween {
  display: flex;
  justify-content: space-between;
}
@mixin box($width, $height: $width) {
  width: $width;
  height: $height;
}
@mixin pseudo($z: 0) {
  position: absolute;
  z-index: $z;
  content: "";
}
@mixin bgImage($position: center, $size: cover, $repeat: no-repeat) {
  background-repeat: $repeat;
  background-size: $size;
  background-position: $position;
}
@mixin absoluteCenter($dir: "both") {
  position: absolute;
  @if $dir == "top" {
    top: 50%;
    transform: translateY(-50%);
  }
  @if $dir == "left" {
    left: 50%;
    transform: translateX(-50%);
  }
  @if $dir == "both" {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

@mixin transition($s: 0.3s) {
  transition: $s ease-in-out;
}
@mixin scrollbar {
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar {
    width: 0.4rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #999;
  }
}
