.pagination {
  margin-top: 2.4rem;
  @include flex-center;
}
.page-list {
  width: 100%;
  @include flex-center;
  flex-wrap: wrap;
  row-gap: 1rem;
  li {
    padding: 0 0.8rem;
  }
}
.page-numbers {
  @include fz-16;
  font-weight: 600;
  line-height: 150%;
  color: #5f626b;
  padding: 0.4rem;
  border-radius: 0.8rem;
  column-gap: .4rem;
  background-color: #fff;
  @include transition;
  @include flex-center;
  flex-wrap: nowrap;
  @include box(3.6rem);
  &:hover {
    background-color: $color-pri;
  }
  svg {
    path {
      fill: #5f626b;
    }
  }
  &.current {
    background-color: $color-pri;
  }
  &.next {
    svg {
      transform: rotate(180deg);
    }
  }
  &.dots {
    border: 0;
    &:hover {
      background-color: #fff;
      color: #5f626b;
    }
  }
  @include max(575.98px) {
    @include box(2.8rem);
  }
}
