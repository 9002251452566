$bg: #fff;
$bg-menu: #242424;
$cl-hover: $color-pri;
$cl: #fff;
$bg-hover: #f9f9f9;
$shadow: 0.05rem 0.1rem 0.2rem 0 rgba(0, 0, 0, 0.25);
$rounded: 1.2rem;
$heightSp: 6.8rem;
$heightTop: 5.2rem;
.nav-wrap {
  position: absolute;
  // position: relative;
  top: 0;
  width: 100%;
  transition: 0.3s ease-in-out;
  background-color: $bg;
  z-index: 20;
  .container {
    max-width: 134rem;
  }
  &.is-fixed {
    @include min(1200px) {
      .nav {
        animation: stuckMoveDown 0.6s;
        position: fixed;
        top: 0;
        // left: 0;
        // right: 0;
        width: 100%;
        box-shadow: rgba(50, 50, 93, 0.25) 0 0.2rem 0.5rem -0.1rem,
          rgba(0, 0, 0, 0.3) 0 0.1rem 0.3rem -0.1rem;
        z-index: 20;
      }
      .nav-outer {
        // height: 0;
        // overflow: hidden;
        // padding: 0;
        // opacity: 0;
      }
      // & + .main {
      //   padding-top: 9rem;
      // }
    }
    @include max(1199.98px) {
      animation: stuckMoveDown 0.6s;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      box-shadow: rgba(50, 50, 93, 0.25) 0 0.2rem 0.5rem -0.1rem,
        rgba(0, 0, 0, 0.3) 0 0.1rem 0.3rem -0.1rem;
      z-index: 20;
    }
  }
  @include max(1199.98px) {
    height: $heightSp;
    display: flex;
    padding-left: 1.5rem;
    align-items: center;
    position: fixed;
    z-index: 20;
    width: 100%;
    top: 0;
    transition: 0.3s ease-in-out;
  }
}
.nav-logo {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  & > a {
    @include flex-center;
  }
  @include max(1199.98px) {
    img {
      height: 4.5rem;
    }
  }
  @include max(767.98px) {
    img {
      height: 3.5rem;
    }
  }
  @include max(575.98px) {
    img {
      height: auto;
      width: 20rem;
    }
  }
}
.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #37003c;
  border-bottom: 0.5px solid rgba(254, 254, 254, 0.1);
  position: relative;
  padding: 1.6rem 0;
  @include transition;
  @include max(1199.98px) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding: 0;
  }
}
.nav-main {
  display: flex;
  align-items: center;
  gap: 5rem;

  .nav-sear {
    display: none;
  }
  .nav-custom {
    display: none;
  }

  @include max(1199.98px) {
    display: block;
    position: absolute;
    top: $heightSp;
    background-color: $bg;
    width: 100%;
    right: -100%;
    height: calc(100vh - #{$heightSp});
    transition: 0.3s ease-in-out;
    &.is-active {
      right: 0;
      overflow: hidden auto;
      &.is-children {
        overflow: unset;
      }
    }
    .nav-sear {
      display: block;
      width: 80%;
      margin-left: auto;
      margin-right: auto;
      margin-top: 2rem;
    }
    .nav-custom {
      display: block;
      li {
        &:first-child {
          display: block;
          width: fit-content;
        }
        &:last-child {
          .custom-item {
            background-color: transparent;
          }
        }
      }
      .custom-content {
        display: flex;
      }
    }
  }
}
.menu {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  justify-content: space-between;
  width: 100%;
  @include max(1199.98px) {
    flex-direction: column;
    align-items: flex-start;
  }
}
.menu-item {
  @include min(1200px) {
    &.js-sub {
      display: flex;
      align-items: center;
      & > .child {
        padding: 1.5rem;
        @include scrollbar;
      }
      &:hover {
        .icon-down {
          transform: rotate(-180deg);
        }
        & > .child {
          opacity: 1;
          display: flex;
        }
      }
    }
    &:hover {
      & > .menu-link {
        color: $color-pri;
        font-weight: 700;
      }
      & > .sub-icon {
        .menu-link {
          color: $color-pri;
          font-weight: 700;
        }
        .icon-down {
          transform: rotate(180deg);
        }
      }
      & > .child {
        display: block;
        opacity: 1;
      }
    }
  }
  &.parent-dropdown {
    position: relative;
    padding-right: 1.5rem;
    &::after {
      @include bgImage;
      position: absolute;
      content: "";
      right: -1rem;
      top: 50%;
      transform: translateY(-50%);
      width: 2rem;
      height: 2rem;
      @include transition;
      background-image: url("../images/common/ico_down.svg");
      filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(53deg)
        brightness(109%) contrast(101%);
    }
    @include min(1200px) {
      &:hover {
        &::after {
          transform: translateY(-50%) rotate(-180deg);
        }
        &::before {
          position: absolute;
          background-color: transparent;
          bottom: -3rem;
          min-width: 30rem;
          right: -1.5rem;
          height: 3rem;
          content: "";
          z-index: 5;
        }
        & > .child {
          display: block;
          opacity: 1;
        }
      }
      & > .child {
        position: absolute;
        padding: 1.5rem;
        top: 170%;
        min-width: 30rem;
        background: #fff;
        max-height: 40rem;
        background: #fff;
        border-radius: 0.4rem;
        overflow: hidden auto;
        display: none;
        opacity: 0;
        right: -1.5rem;
        @include transition;
        z-index: 100;
        box-shadow: 0.05rem 0.1rem 0.2rem 0 rgba(0, 0, 0, 0.25);
        .menu-link {
          padding: 1rem;
          color: $color-text;
          text-transform: unset;

          &:hover {
            font-weight: 500;
            text-decoration: underline;
          }
        }
      }
    }
    @include max(1199.98px) {
      &::after {
        right: 1.5rem;
        filter: unset;
        top: 2rem;
        transform: unset;
      }
      &.is-active {
        &:after {
          transform: rotate(-180deg);
        }
      }
    }
  }
  @include max(1199.98px) {
    padding: 1.5rem;
    width: 100%;
    &.is-active {
    }
  }
}
.menu-link,
.menu-title {
  color: $cl;
  @include fz-15;
  font-weight: 500;
  line-height: 150%;
  display: block;
  @include transition;
  text-transform: uppercase;
  @include max(1199.98px) {
    padding: 0;
    color: $color-text;
  }
}
.menu-link {
  @include max(1199.98px) {
    width: fit-content;
  }
}
.menu-title {
  color: #000;
  font-weight: 700;
  padding: 1rem;
  @include fz-18;
  &:hover {
    color: $color-pri;
  }

  @include max(800px) {
    font-size: 1.5rem;
  }
  @include max(575.98px) {
    padding: 1rem 0;
  }
}
// menu bar
.nav-bar {
  position: relative;
  cursor: pointer;
  padding: 0 1.5rem;
  display: none;
  height: 100%;
  width: 5.4rem;
  &.is-active {
    .menu-icon {
      background: transparent;
      &::before,
      &::after {
        top: 0;
        transition: top ease 0.3s, transform ease 0.3s 0.3s;
      }
      &::before {
        transform: rotate(45deg);
      }
      &::after {
        transform: rotate(-45deg);
      }
    }
  }
  @include max(1199.98px) {
    display: block;
  }
}
.menu-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: $bg-menu;
  display: block;
  height: 0.2rem;
  transition: background ease 0.3s, top ease 0.3s 0.3s, transform ease 0.3s;
  width: 2.4rem;
  &::before,
  &::after {
    background: $bg-menu;
    content: "";
    display: block;
    height: 0.2rem;
    position: absolute;
    transition: background ease 0.3s, top ease 0.3s 0.3s, transform ease 0.3s;
    width: 2.4rem;
  }
  &::before {
    top: -0.6rem;
  }
  &::after {
    top: 0.6rem;
  }
}
.nav-sear {
  flex: 1;
  overflow: hidden;
  max-width: 45rem;
  form {
    position: relative;
    border-radius: 10rem;
    width: 100%;
    height: 100%;
    // border-bottom: 0.1rem solid #e6e6e6;
    // padding-bottom: 0.5rem;
    background-color: #e6e6e6;
    overflow: hidden;
  }
  input {
    // height: 4.8rem;
    font-family: var(--font-pri);
    @include fz-16;
    width: 100%;
    border: 0;
    border-radius: 10rem;
    background-color: #e6e6e6;
    color: $color-text;
    padding: 0.8rem 1.6rem;
    font-weight: 600;
    &::placeholder {
      color: #999;
    }
    &:focus {
      border: 0;
      outline: none;
      box-shadow: none;
    }
  }
  button {
    @include absoluteCenter(top);
    height: 100%;
    padding-right: 2.4rem;
    padding-left: 1rem;
    right: 0;
    img {
      width: 2rem;
    }
  }
  .menu {
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 1rem;
    @include max(1250.98px) {
      column-gap: 0.5rem;
    }
  }
  .menu-item {
    &:hover {
      & > .menu-link {
        &:hover {
          font-weight: 500;
        }
      }
    }
  }
  .menu-link {
    color: $color-text;
    @include fz-14;
    text-transform: unset;
    font-weight: 500;
    @include max(1250.98px) {
      @include fz-13;
    }
  }
  @include max(1199.98px) {
    display: none;
  }
}
.js-sub {
  @include min(1200px) {
    &:hover {
      &::before {
        @include pseudo(5);
        background-color: transparent;
        width: 100%;
        height: 3rem;
        bottom: -1rem;
        left: 0;
      }
    }
  }
  .bxs-chevron-down {
    display: none;
  }
  & > .child {
    @include min(1200px) {
      transition: 0.3s ease-in-out;
      display: none;
      position: absolute;
      min-width: 15rem;
      top: 100%;
      opacity: 0;
      left: 0;
      right: 0;
      z-index: 10;
      background: $bg;
      box-shadow: $shadow;
      flex-wrap: wrap;
      max-height: 70vh;
      overflow: hidden auto;
      & > .menu-item {
        width: calc(100% / 7);
      }
      .container {
        max-height: 72vh;
        overflow: hidden auto;
        &::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          background-color: #f5f5f5;
        }

        &::-webkit-scrollbar {
          width: 0.4rem;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #999;
        }
      }

      // .child {
      //   // top: 0;
      //   // left: 90%;
      //   // height: 100%;
      //   .menu {
      //     height: 100%;
      //     overflow: hidden auto;
      //   }
      //   &::before {
      //     // height: 100%;
      //     // width: 3rem;
      //     // left: -2rem;
      //     // top: 0;
      //   }
      // }
      .menu-link {
        &:hover {
          text-decoration: underline;
        }
      }
    }
    & > .menu-item {
      & > .menu-link {
        color: #000;
        font-weight: 700;
        width: fit-content;
        &:hover {
          font-weight: 700;
          color: #fbbf24;
        }
      }
    }
    .menu-link {
      padding: 1rem;
      color: $color-text;
      text-transform: unset;
      @include fz-16;
      &:hover {
        font-weight: 500;
      }

      @include max(575.98px) {
        padding: 1rem 0;
      }
    }
    .menu-item {
      @include min(1200px) {
        &:hover {
          & > .child {
            top: 0;
            left: 100%;
          }
        }
      }
    }
    @include max(1199.98px) {
      position: absolute;
      top: 0;
      left: 100%;
      width: 100%;
      background-color: #fff;
      z-index: 40;
      height: 100%;
      padding: 1.5rem;
      overflow: hidden auto;
      @include transition;
      .container {
        overflow: hidden auto;
        height: 100vh;
      }
      padding-top: 4rem;
      &::before {
        position: absolute;
        top: 1rem;
        left: 1.5rem;
        width: 2rem;
        height: 2rem;
        transform: rotate(90deg);
        content: "";
        @include bgImage(center, contain);
        background-image: url("../images/common/ico_down.svg");
      }
      .menu-item {
        &:first-child {
          margin-top: 1rem;
        }
      }

      &.is-active {
        left: 0;
      }
    }

    @include max(991px) {
      .menu {
        box-shadow: none;
      }
      .menu-item {
        padding: 0;
      }
      .menu-link {
        justify-content: flex-start;
      }
      .icon-down {
        flex: 1;
      }
      .child {
        position: relative;
        // top: $heightSp;
        // width: 100%;
        // height: calc(100vh - 5.2rem);
        // right: -100%;
        // background: $bg;
        &.is-active {
          right: 0;
        }
        .menu {
          background-color: $bg;
          height: 100%;
          overflow: hidden auto;
        }
      }
    }
  }
  @include max(1199.98px) {
    display: flex;
    justify-content: space-between;
  }
}

.child-inner {
  @include flex;
  gap: 2rem;
  // justify-content: space-between;
  @include max(575.98px) {
    column-gap: 1.5rem;
  }
}
.sub-icon {
  @include flex-icenter;
}
.icon-down {
  margin-left: 0.5rem;
  @include flex-icenter;
  @include transition;
  img {
    filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(53deg)
      brightness(109%) contrast(101%);
    width: 2rem;
  }
  @include max(1199.98px) {
    img {
      filter: unset;
      width: 1.8rem;
    }
    transform: rotate(-90deg);
  }
}
.icon-back {
  display: none;

  @include max(1199.98px) {
    display: block;
    padding: 1rem 1.5rem;
    width: 100%;
    img {
      transform: rotate(90deg);
      width: 2.4rem;
    }
  }
}

.nav-outer {
  padding: 1.6rem 0;
  height: 100%;
  opacity: 1;
  @include transition;
  @include max(1199.98px) {
    padding: 0.5rem 0;
    flex: 1;
    .container {
      padding: 0;
    }
  }
}
.nav-top {
  @include flex-icenter;
  justify-content: space-between;
  gap: 2rem;
  @include max(1199.98px) {
    justify-content: space-between;
    height: 100%;
  }
}
.nav-custom {
  @include flex-icenter;
  column-gap: 1.6rem;
  li {
    &:last-child {
      .custom-item {
        column-gap: 0.2rem;
        background-color: $color-pri;
        border-radius: 0.8rem;
        padding: 0.8rem 1.4rem;
        @include fz-14;
        font-weight: 600;
        & > img {
          margin-right: 0.6rem;
        }
        a {
          @include transition;
          font-weight: 700;
          &:hover {
            color: #fff;
          }
        }
      }
    }
  }
  @include max(1199.98px) {
    // flex: unset;
    li {
      &:first-child {
        display: none;
      }
    }
  }
}
.custom-item {
  @include flex-icenter;
  column-gap: 1.2rem;
  padding: 0.8rem;
  position: relative;
  &:hover {
    .custom-content {
      span {
        &:nth-child(2) {
          color: $color-sec;
        }
      }
    }
  }
  @include max(640.98px) {
    .is-pc {
      display: none;
    }
  }
}
.custom-content {
  @include flex;
  flex-direction: column;
  span {
    font-weight: 600;
    line-height: 150%;
    @include transition;
    &:nth-child(1) {
      color: #64748b;
      @include fz-12;
    }

    &:nth-child(2) {
      color: #000;
      @include fz-16;
    }
  }
  @include max(640.98px) {
    display: none;
  }
}
.cart-num {
  display: none;
  @include max(640.98px) {
    @include flex-center;
    position: absolute;
    border-radius: 50%;
    background: $color-pri;
    padding: 0.5rem;
    @include fz-12;
    right: -0.5rem;
    top: 0;
    line-height: 100%;
    font-weight: 700;
    @include box(2.4rem);
    overflow: hidden;
  }
}
.custom-login {
  @include max(575.98px) {
    @include flex-center;
    img {
      max-width: 2rem;
      min-width: 1.6rem;
      margin-right: 0 !important;
    }
    span {
      display: none;
    }
  }
}
.custom-cart {
  img {
    min-width: 2.8rem;
  }
}
@keyframes stuckMoveDown {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}
.stuckMoveUp {
  animation: stuckMoveUp 0.2s;
}
@keyframes stuckMoveUp {
  0% {
    transform: translateY(30%);
  }
  100% {
    transform: translateY(0%);
  }
}
.is-icon {
  position: relative;
  &::before {
    position: absolute;
    content: "";
    width: 2rem;
    height: 2rem;
    @include bgImage;
    background-image: url("../images/common/ico_down.svg");
    top: 1rem;
    right: 0;
    @include transition;
  }
  &.is-active {
    &::before {
      transform: rotate(-180deg);
    }
  }
}
#mona-cart-qty,
#number-cart-pc {
  color: #000;
  @include fz-16;
  font-weight: 600;
  line-height: 150%;
}
